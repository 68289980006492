import React, { useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';

function EmployerprotectedRoute({Component}) {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const userType = localStorage.getItem('userType');
        
        if (!userType) {
            // Store the full URL including search params
            const fullPath = location.pathname + location.search;
            localStorage.setItem('intendedPath', fullPath);
            navigate('/login');
        } else if (userType !== 'Employer') {
            // If user is logged in but not an employer
            if (location.pathname.includes('employerappreceived')) {
                navigate('/notfound');
            } else {
                navigate('/');
            }
        }
    }, [navigate, location]);

    // Only render if user is an employer
    return <>{localStorage.getItem('userType') === 'Employer' ? <Component /> : null}</>;
}

export default EmployerprotectedRoute