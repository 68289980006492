import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import { debounce } from 'lodash';
import axios from "axios";

const ProjectCategorySelector = ({
  setSelectedOption,
  setSpecializationData,
  resetSpecialization,
  selectedData,
}) => {
  const [options, setOptions] = useState([]);

  // Fetch categories only once on mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`/api/data/project-category`);
        const categoryOptions = response?.data?.map((cat) => ({
          value: cat,
          label: cat,
        }));
        setOptions(categoryOptions || []);
      } catch (error) {
        console.error("Error fetching job categories:", error);
      }
    };
    fetchCategories();
  }, []);

  // Debounced specialization fetch
  const debouncedSpecializationFetch = useCallback(
    debounce(async (value) => {
      if (!value) return;

      try {
        const response = await axios.get(`/api/data/specialization`, {
          params: { profileCategory: value },
        });
        const specializationOptions = response?.data?.map((sp) => ({
          value: sp,
          label: sp,
        }));
        setSpecializationData(specializationOptions || []);
      } catch (error) {
        console.error("Error fetching specializations:", error);
        setSpecializationData([]);
      }
    }, 300),
    [setSpecializationData]
  );

  // Handle category selection
  const handleChange = (option) => {
    setSelectedOption(option);
    if (option?.value) {
      debouncedSpecializationFetch(option.value);
      resetSpecialization();
    }
  };

  return (
    <div className="job-select-container">
      <Select
        options={options}
        onChange={handleChange}
        value={selectedData}
        classNamePrefix="react-select"
        placeholder="Select Category.."
      />
    </div>
  );
};

export default React.memo(ProjectCategorySelector);