import React, { useEffect } from "react";
import { GoDotFill } from "react-icons/go";
import "./css/AboutUs.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { logEvent } from "../../utils/Analytics";
const HowItWorks = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const { user } = useSelector((state) => state.user);

    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
    };
    return (
        <div className="howitwork-wrapper" style={{ backgroundColor: "white", marginTop: "13rem" }}>
            <h1 className="subHeading">If you are hiring</h1>
            <br />
            <h1 className="subHeading">Easily find quality freelancers</h1>
            <br />
            <p className="subHeading_2">On Workcroft, you'll find a range of top talent, from programmers to designers, writers, data scientists, data engineers, analysts, researchers, and more.</p>
            <br />

            <ul>
                <li className="howItWorkContent">Start by posting a job. Tell us about your project and the specific skills required.</li>
                <br />
                <li className="howItWorkContent">Workcroft analyzes your needs. Our search functionality uses data science to highlight freelancers based on their skills, helping you find talent that's a good match.</li>
                <br />
                <li className="howItWorkContent">We send you a shortlist of likely candidates. You can also search our site for talent, and freelancers can view your job and submit proposals too.</li>
            </ul>

            <br />

            <h1 className="subHeading">Hire the best freelancer</h1>

            <br />

            <p className="subHeading_2">Invite candidates to submit bids, then interview and hire your choice.</p>
            <br />

            <ul>
                <li className="howItWorkContent">Browse profiles. View finalists' Workcroft profiles to see skills, portfolio, and more.</li> <br />
                <li className="howItWorkContent">Review proposals. Evaluate bids, taking into account each freelancer's qualifications, thought process, timeline, and overall cost.</li> <br />
                <li className="howItWorkContent">Schedule a chat. Ask specific questions, determine who’s the best fit, and contract.</li>
            </ul>

            <br />

            <h1 className="subHeading">Work efficiently, and effectively</h1>
            <br />

            <p className="subHeading_2">Each project includes a standalone workspace of your choice by your team and your freelancer, allowing you to:</p>
            <br />

            <ul>
                <li className="howItWorkContent">Send and receive files. Use any mode of communication, such as email, Skype, file sharing tools, Google, Dropbox, and more.</li> <br />
                <li className="howItWorkContent">Share feedback in real time. Use any messaging tool and software such as email, Skype, etc. to communicate via text, chat, or video.</li> <br />
                <li className="howItWorkContent">Use your favorite communication tool to chart and call with your team and freelancers.</li>
            </ul>

            <br />
            <h1 className="subHeading">Pay easily, with peace of mind</h1>
            <br />
            <p className="subHeading_2">Pay your freelancer by the hour, or a fixed price for the entire project or for each milestones, or a fixed cost on a project-to-project basis.</p>
            <br />

            <ul>
                <li className="howItWorkContent">Pay using your favorite wire transfer site or software like PayPal, credit cards, debit cards, etc</li> <br />
                <li className="howItWorkContent">Workcroft accepts payments from over 170 countries in their currency.</li> <br />
                <li className="howItWorkContent">Only pay for work you authorize and approve.</li> <br />
                <li className="howItWorkContent">Workcroft team sends invoices only after successful delivery of jobs, tasks, milestone, and projects.</li>
            </ul>
            <br />
            <div style={{ height: "175px", backgroundColor: "#1F57C3", textAlign: "center", justifyContent: "center", padding: "56px" }}>
                <h1 style={{ color: "white", fontWeight: "bold", fontSize: "24px" }}>Ready to find a freelancer?</h1>
                <Link to={!user ? "/login" : "/freelancer-details"} onClick={() => handleAddEvent("Get started")}>
                    <p style={{ color: "white", fontSize: "12px", border: "1px solid white", padding: "7px", display: "inline-block", marginTop: "5px" }}>Get Started</p>
                </Link>
            </div>
            <br />
            <br />
            <br />
            <h1 className="subHeading">If you are freelancing</h1>
            <br />
            <br />
            <h1 className="subHeading">Find rewarding projects</h1>
            <br />
            <p className="subHeading_2">Workcroft is a great place to find more clients and to run and grow your own freelance business.</p>
            <br />
            <ul>
                <li className="howItWorkContent">Freedom to work on ideal projects. On Workcroft, you run your own business and choose your own clients and projects. Just finish your profile and we’ll highlight ideal jobs. Also search projects, and respond to client invitations.</li> <br />
                <li className="howItWorkContent">Wide variety and high pay. Clients are now posting jobs in hundreds of skill categories, paying top prices for great work.</li> <br />
                <li className="howItWorkContent">More and more success. The greater the success you have on projects, the more likely you are to get hired by clients who use Workcroft.</li> <br />
            </ul>

            <br />

            <h1 className="subHeading">Get hired quickly</h1>
            <br />
            <p className="subHeading_2">Workcroft makes it easy to connect with clients and begin doing great work.</p>
            <br />

            <ul>
                <li className="howItWorkContent">Streamlined hiring. Workcroft’s sophisticated algorithms highlight projects you’re a great fit for.</li> <br />
                <li className="howItWorkContent">Top Rated and Rising Talent programs. Enjoy higher visibility with the added status of prestigious programs.</li> <br />
                <li className="howItWorkContent">Do substantial work with top clients. Workcroft pricing encourages freelancers to use Workcroft for repeat relationships with their clients.</li> <br />
            </ul>

            <br />

            <h1 className="subHeading">Work efficiently, and effectively</h1>
            <br />
            <p className="subHeading_2">With Workcroft, you have the freedom and flexibility to control when, where, and how you work. Each project includes an online workspace shared by you and your client, allowing you to:</p>
            <br />

            <ul>
                <li className="howItWorkContent">Send and receive files. Deliver digital assets in a secure environment.</li> <br />
                <li className="howItWorkContent">Share feedback in real time. Use Workcroft Messages to communicate via text, chat, or video.</li> <br />
                <li className="howItWorkContent">Use our mobile app. Many features can be accessed on your mobile phone when on the go.</li> <br />
            </ul>

            <br />

            <h1 className="subHeading">Get paid on time</h1>
            <br />
            <p className="subHeading_2">All projects include any payment method of your choice or client’s choice - helping ensure that you get paid for all work successfully completed through any payment method you or your client prefer.</p>
            <br />
            <ul>
                <li className="howItWorkContent">All invoices and payments happen through any preferred payment method. Count on a simple and streamlined process.</li> <br />
                <li className="howItWorkContent"> Hourly and fixed-price projects. For hourly work, submit timesheets through Workcroft or any communication mode. For fixed-price jobs, set milestones and funds are released using any preferred payment methods.</li> <br />
                <li className="howItWorkContent">Multiple payment options. Choose a payment method that works best for you, from direct deposit or PayPal to wire transfer and more.</li> <br />
            </ul>

            <br />

            <h1 className="subHeading">Service fees for freelancers</h1>
            <br />
            <p className="subHeading_2">Workcroft charges freelancers a sliding fee based on the freelancer's lifetime billings with a specific client. In other words, the more business a freelancer has done with a client, the more they’ll earn.</p>
            <br />
            <ul>
                <li className="howItWorkContent">Applying for an hourly project requires 8 connects.</li> <br />
                <li className="howItWorkContent">Applying for a fixed cost project requires 4 connects.</li> <br />
                <li className="howItWorkContent">To access a freelancer's contact information (email, phone, or direct chat) on the "Find Talent" page, you must use one Connect.</li> <br />
            </ul>

            <br />
            <div style={{ height: "175px", backgroundColor: "#1f57c3", textAlign: "center", justifyContent: "center", padding: "56px" }}>
                <h1 style={{ color: "white", fontWeight: "bold", fontSize: "24px" }}>Ready to get hired?</h1>
                <Link to={!user ? "/freelancerSignup" : "/matchedHourlyProject/all"} onClick={() => handleAddEvent("Become a freelancer")}>
                    <p style={{ color: "white", fontSize: "12px", border: "1px solid white", padding: "7px", display: "inline-block", marginTop: "5px" }}>Become a freelancer</p>
                </Link>
            </div>
            <br />
            <br />
        </div>
    );
};

export default HowItWorks;
