import React, { useEffect } from "react";
import "./css/TalentMarketPlace.scss";
import { talentMarketPlace_1, talentMarketPlace_2, talentMarketPlace_3, talentMarketPlace_4, talentMarketPlace_5, talentMarketPlace_6, talentMarketPlace_7, talentMarketPlace_8, talentMarketPlace_9, talentMarketPlace_10, talentMarketPlace_11, talentMarketPlace_12, talentMarketPlace_13, network_2, legal, building } from "../../assets";
import { Link, Navigate, useNavigate } from "react-router-dom";
import JobCategoryCard from "./components/JobCategoryCard";
import { useSelector } from "react-redux";
import { logEvent } from "../../utils/Analytics";

const TalentMarketPlace = () => {
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    const categories = [
        {
            icon: `${talentMarketPlace_7}`,
            title: "Accounting & Consulting",
            jobsCount: "15K+ jobs posted weekly",
            searchKeyword: "Accounting & Consulting",
        },
        {
            icon: `${talentMarketPlace_6}`,
            title: "Admin Support",
            jobsCount: "10K+ jobs posted weekly",
            searchKeyword: "Admin Support",
        },
        {
            icon: `${talentMarketPlace_5}`,
            title: "Data Science & Analytics",
            jobsCount: "10K+ jobs posted weekly",
            searchKeyword: "Data Science & Analytics",
        },
        {
            icon: `${talentMarketPlace_4}`,
            title: "Design & Creative",
            jobsCount: "15K+ jobs posted weekly",
            searchKeyword: "Design & Creative",
        },
        {
            icon: `${building}`,
            title: "Engineering & Architecture",
            jobsCount: "5K+ jobs posted weekly",
            searchKeyword: "Engineering & Architecture",
        },
        {
            icon: `${network_2}`,
            title: "IT & Networking",
            jobsCount: "12K+ jobs posted weekly",
            searchKeyword: "IT & Networking",
        },
        {
            icon: `${legal}`,
            title: "Legal",
            jobsCount: "12K+ jobs posted weekly",
            searchKeyword: "Legal",
        },
        {
            icon: `${talentMarketPlace_3}`,
            title: "Sales & Marketing",
            jobsCount: "10K+ jobs posted weekly",
            searchKeyword: "Sales & Marketing",
        },
        {
            icon: `${talentMarketPlace_2}`,
            title: "Web, Mobile & Software Development",
            jobsCount: "20K+ jobs posted weekly",
            searchKeyword: "Web, Mobile & Software Development",
        },
        {
            icon: `${talentMarketPlace_5}`,
            title: "Writing",
            jobsCount: "20K+ jobs posted weekly",
            searchKeyword: "Writing",
        },
    ];
    const benefits = [
        {
            icon: `${talentMarketPlace_10}`,
            title: "Find top-tier talent quickly",
            features: ["Post a job and get proposals from talented individuals", "View verified work history and reviews", "View verified work history and reviews", "Send up to 30 invitations to potential talents per job post"],
        },
        {
            icon: `${talentMarketPlace_11}`,
            title: "Safe, simple payment processes",
            features: ["Ensure added security with Workcroft Payment Protection", "Billing is based on pay-as-you-go for both milestone and hourly contracts.", "Marketplace fees range from 3% to 5% depending on the billing method."],
        },
        {
            icon: `${talentMarketPlace_12}`,
            title: "Collaboration tools for project tracking",
            features: ["Message, video call, and share files seamlessly with talent", "Access advanced reporting and tracking", "Set coworker teams and member permission settings", "Customize your invoice with activity codes"],
        },
        {
            icon: `${talentMarketPlace_13}`,
            title: "Account support and guidance",
            features: ["Account support and guidance"],
        },
    ];
    const handleNavigateToJobPage = (category) => {
        console.log(category);
        sessionStorage.setItem("selectedCategoryTalentMarketPlace", category);
        navigate("/matchedHourlyProject/all");
    };
    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
    };

    return (
        <>
            <div className="marketplace-container">
                {/* Hero Section */}
                <div className="marketplace-hero-section">
                    <div className="hero-content">
                        <div className="">
                            <h1>Post a job today and hire by tomorrow</h1>
                            <p>Find talent that understands your vision, and hire them to elevate your business.</p>
                            <a href={!user ? "/signup" : user?.role === "User" ? "freelancer-details" : "/talent-marketplace"} style={{ color: "white" }} onClick={() => handleAddEvent("Get started")}>
                                <button className="primary-btn">Get Started</button>
                            </a>
                        </div>
                    </div>
                    <div className="hero-image">
                        <img src={talentMarketPlace_1} alt="Person working on laptop" />
                    </div>
                </div>
            </div>

            <div className="category-wrapper">
                <div className="categories-container">
                    <div className="categories-header">
                        <h2>The ideal colleagues you've yet to meet</h2>
                        <p>Logo designers, App developers, Customer support experts, Marketing agencies. Forge the right connection, and it will last a lifetime.</p>
                    </div>

                    <div className="categories-grid">
                        {categories.map((category, index) => (
                            <div className="category-card" key={index} onClick={() => handleNavigateToJobPage(category.searchKeyword)}>
                                <div className="category-icon">
                                    <img src={category.icon} alt="" />
                                </div>
                                <div className="category-content">
                                    <h3>{category.title}</h3>
                                    <p>{category.jobsCount}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Job Post Section */}
            <div className="marketplace-container" style={{ marginTop: "0rem" }}>
                <div className="job-post-section">
                    <div className="job-post-image">
                        <img src={talentMarketPlace_8} alt="Person in library" />
                    </div>
                    <div className="job-post-content">
                        <h2>Post today, hire by tomorrow</h2>
                        <p>We've got you covered from idea to delivery. Post your job and start receiving proposals right away. Once you've found your expert, you can discuss timelines, availability, and pricing before moving forward.</p>

                        <div className="payment">
                            <img src={talentMarketPlace_9} alt="" />
                            <div className="payment-content">
                                <h3>Workcroft Payment Protection</h3>
                                <p>Gives you security and peace of mind</p>
                            </div>
                        </div>
                        {user?.role !== "User" && (
                            <Link to="/newpost/0" style={{ color: "white" }} onClick={() => handleAddEvent("Post a job ")}>
                                <button className="post-btn">Post a job</button>
                            </Link>
                        )}
                    </div>
                </div>

                <div className="marketPlace-benefits-container">
                    <h2>What you will receive</h2>

                    <div className="benefits-grid">
                        {benefits.map((benefit, index) => (
                            <div className="benefit-card" key={index}>
                                <div className="benefit-header">
                                    <span className="benefit-icon">
                                        <img src={benefit.icon} alt="" />
                                    </span>
                                </div>
                                <ul className="benefit-features">
                                    <h3>{benefit.title}</h3>
                                    {benefit.features.map((feature, featureIndex) => (
                                        <li key={featureIndex}>
                                            <span className="bullet">•</span>
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TalentMarketPlace;
