import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function StudentprotectedRoute({ Component }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const userType = localStorage.getItem('userType');
    
    if (!userType) {
      // Store the full URL including search params
      const fullPath = location.pathname + location.search;
      localStorage.setItem('intendedPath', fullPath);
      navigate('/login');
    } else if (userType === 'Employer') {
      // If user is logged in as employer, prevent access to student routes
      if (location.pathname.includes('/studentapplications')) {
        navigate('/notfound');
      } else {
        navigate('/');
      }
    }
  }, [navigate, location]);

  // Only render if user is a student/user
  return <>{localStorage.getItem('userType') === 'User' ? <Component /> : null}</>;
}

export default StudentprotectedRoute;