import React, { useEffect, useState } from "react";
import "./css/HireWorldWide.scss";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { dollarCoin, rocket, search, solarsShield, downArrow } from "../../assets";
import { useSelector } from "react-redux";
import { logEvent } from "../../utils/Analytics";
const HireWorldWide = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const { user } = useSelector((state) => state.user);

    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
    };
    const steps = [
        {
            number: `${solarsShield}`,
            title: "Over 1M reviews",
            description: "Build connections with top-rated professionals",
        },
        {
            number: `${rocket}`,
            title: "Protected payments",
            description: "Effortless billing so you can concentrate on meaningful work",
        },
        {
            number: `${dollarCoin}`,
            title: "Hire who you need",
            description: "Discover professionals who can start immediately and tackle any task",
        },
    ];
    const customStyles = {
        container: (provided) => ({
            ...provided,
            maxWidth: "600px",
            width: "100%",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#959595",
            fontSize: "16px",
            fontWeight: "400",
        }),
        control: (provided, state) => ({
            ...provided,
            border: "1px solid #696969",
            borderRadius: "9999px",
            minHeight: "48px",
            padding: "0 8px",
            boxShadow: "none",
            backgroundColor: "#f5f7ff",
            "&:hover": {
                borderColor: "#e5e7eb",
            },
        }),
        input: (provided) => ({
            ...provided,
            color: "#374151",
            fontSize: "16px",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: "8px",
            color: "#3b82f6",
            cursor: "pointer",
            ":hover": {
                color: "#2563eb",
            },
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: "2px 16px",
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: "12px",
            overflow: "hidden",
            marginTop: "8px",
            boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#f3f4f6" : "transparent",
            color: state.isFocused ? "#3b82f6" : "#374151",
            padding: "12px 16px",
            cursor: "pointer",
            ":hover": {
                backgroundColor: "#f3f4f6",
                color: "#3b82f6",
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: "#e5e7eb",
            borderRadius: "9999px",
            padding: "2px 4px",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: "#374151",
            fontSize: "14px",
            padding: "2px 8px",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: "#6b7280",
            ":hover": {
                backgroundColor: "#d1d5db",
                color: "#374151",
            },
        }),
    };

    const CustomDropdownIndicator = (props) => {
        return (
            <div {...props.innerProps}>
                <img src={search} alt="" />
                {/* <Search size={20} color="#3b82f6" /> */}
            </div>
        );
    };
    const fetchData = async () => {
        try {
            const categoryResponse = await axios.get(`/api/data/project-category`);
            const categories = categoryResponse?.data || [];

            const categorySpecializationData = await Promise.all(
                categories.map(async (category) => {
                    const specializationResponse = await axios.get(`/api/data/specialization`, {
                        params: { profileCategory: category },
                    });
                    const specializations = specializationResponse?.data || [];
                    return {
                        category,
                        specializations,
                    };
                })
            );

            setCategoryData(categorySpecializationData);
        } catch (error) {
            console.error("Error fetching categories and specializations:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    const [soloTeam, setSoloTeam] = useState("Solo");
    const [skillInputSearchTerm, setSkillInputSearchTerm] = useState("");
    const [allskills, setAllSkills] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [activeIndices, setActiveIndices] = useState(new Set());
    const [categoryData, setCategoryData] = useState([]);
    const data = [
        {
            category: "Accounting & Consulting",
            details: {
                soloProfessionals: ["Personal & Professional Coaching", "Accounting & Bookkeeping", "Financial Planning", "Recruiting & Human Resources", "Management Consulting & Analysis", "Other - Accounting & Consulting"],
                teams: ["Personal & Professional Coaching", "Accounting & Bookkeeping", "Financial Planning", "Recruiting & Human Resources", "Management Consulting & Analysis", "Other - Accounting & Consulting"],
            },
        },
        {
            category: "Admin Support",
            details: {
                soloProfessionals: ["Data Entry & Transcription Services", "Virtual Assistance", "Project Management", "Market Research & Product Reviews", "Customer Service", "Community Management & Tagging", "Customer Service & Tech Support"],
                teams: ["Data Entry & Transcription Services", "Virtual Assistance", "Project Management", "Market Research & Product Reviews", "Customer Service", "Community Management & Tagging", "Customer Service & Tech Support"],
            },
        },
        {
            category: "Data Science & Analytics",
            details: {
                soloProfessionals: ["Data Analysis", "Data Extraction/ETL", "Data Mining & Management", "AI & Machine Learning", "Data Engineering", "Data Visualization"],
                teams: ["Data Analysis", "Data Extraction/ETL", "Data Mining & Management", "AI & Machine Learning", "Data Engineering", "Data Visualization"],
            },
        },
        {
            category: "Design & Creative",
            details: {
                soloProfessionals: ["Art & Illustration", "Audio & Music Production", "Branding & Logo Design", "NFT, AR/VR & Game Art", "Graphic, Editorial & Presentation Design", "Performing Arts", "Photography", "Product Design", "Video & Animation"],
                teams: ["Art & Illustration", "Audio & Music Production", "Branding & Logo Design", "NFT, AR/VR & Game Art", "Graphic, Editorial & Presentation Design", "Performing Arts", "Photography", "Product Design", "Video & Animation"],
            },
        },
        {
            category: "Engineering & Architecture",
            details: {
                soloProfessionals: ["Building & Landscape Architecture", "Chemical Engineering", "Civil & Structural Engineering", "Contract Manufacturing", "Electrical & Electronic Engineering", "Interior & Trade Show Design", "Energy & Mechanical Engineering", "Physical Sciences", "3D Modeling & CAD"],
                teams: ["Building & Landscape Architecture", "Chemical Engineering", "Civil & Structural Engineering", "Contract Manufacturing", "Electrical & Electronic Engineering", "Interior & Trade Show Design", "Energy & Mechanical Engineering", "Physical Sciences", "3D Modeling & CAD"],
            },
        },
        {
            category: "IT & Networking",
            details: {
                soloProfessionals: ["Database Management & Administration", "ERP/CRM Software", "Information Security & Compliance", "Network & System Administration", "DevOps & Solution Architecture"],
                teams: ["Database Management & Administration", "ERP/CRM Software", "Information Security & Compliance", "Network & System Administration", "DevOps & Solution Architecture"],
            },
        },
        {
            category: "Legal",
            details: {
                soloProfessionals: ["Corporate & Contract Law", "International & Immigration Law", "Finance & Tax Law", "Public Law"],
                teams: ["Corporate & Contract Law", "International & Immigration Law", "Finance & Tax Law", "Public Law"],
            },
        },
        {
            category: "Sales & Marketing",
            details: {
                soloProfessionals: ["Digital Marketing", "Lead Generation & Telemarketing", "Marketing, PR & Brand Strategy", "Translation", "Language Tutoring & Interpretation", "Translation & Localization Services"],
                teams: ["Digital Marketing", "Lead Generation & Telemarketing", "Marketing, PR & Brand Strategy", "Translation", "Language Tutoring & Interpretation", "Translation & Localization Services"],
            },
        },
        {
            category: "Web, Mobile & Software Development",
            details: {
                soloProfessionals: ["Blockchain, NFT & Cryptocurrency", "AI Apps & Integration", "Desktop Application Development", "Ecommerce Development", "Game Design & Development", "Mobile Development", "QA Testing", "Scripts & Utilities", "Web & Mobile Design", "Web Development", "Other - Software Development"],
                teams: ["Blockchain, NFT & Cryptocurrency", "AI Apps & Integration", "Desktop Application Development", "Ecommerce Development", "Game Design & Development", "Mobile Development", "QA Testing", "Scripts & Utilities", "Web & Mobile Design", "Web Development", "Other - Software Development"],
            },
        },
        {
            category: "Writing",
            details: {
                soloProfessionals: ["Sales & Marketing Copywriting", "Content Writing", "Editing & Proofreading Services", "Professional & Business Writing"],
                teams: ["Sales & Marketing Copywriting", "Content Writing", "Editing & Proofreading Services", "Professional & Business Writing"],
            },
        },
    ];

    useEffect(() => {
        console.log("fetch skills");

        async function fetchAllSkills() {
            if (skillInputSearchTerm.trim()) {
                try {
                    const response = await axios.get(`/api/data/skills`, {
                        params: { keyword: skillInputSearchTerm },
                    });
                    if (Array.isArray(response.data)) {
                        setAllSkills(response.data.map((skill) => ({ label: skill, value: skill })));
                    } else {
                        console.error("Unexpected response format:", response.data);
                    }
                } catch (error) {
                    console.error("Error fetching skills:", error);
                }
            } else {
                setAllSkills([]);
            }
        }
        fetchAllSkills();
    }, [skillInputSearchTerm]);

    const handleSkillChange = (selectedOptions) => {
        console.log(selectedOptions);
        setSelectedSkill(selectedOptions);
    };
    console.log(selectedSkill);

    const handleSkillInputChange = (newValue) => {
        setSkillInputSearchTerm(newValue);
        setIsTyping(newValue.length > 0);
    };

    const navigateToFreelancerDetails = () => {
        if (selectedSkill.length > 0) {
            // Store selected skills in session storage
            sessionStorage.setItem("selectedSkills", JSON.stringify(selectedSkill));

            // Navigate to freelancer details page
            navigate("/freelancer-details");
        }
    };

    const navigateToFreelancerDetailsForProfile = (selectedProfile) => {
        console.log(selectedProfile);
        sessionStorage.setItem("selectedProfile", selectedProfile);
        navigate("/freelancer-details");
    };

    const toggleAccordion = (index) => {
        console.log(index);

        const newActiveIndices = new Set(activeIndices);
        if (newActiveIndices.has(index)) {
            newActiveIndices.delete(index);
        } else {
            newActiveIndices.add(index);
        }
        setActiveIndices(newActiveIndices);
    };

    const handleContentChange = () => {
        if (soloTeam === "Solo") {
            setSoloTeam("Team");
        } else {
            setSoloTeam("Solo");
        }
    };

    return (
        <div className="hire-worldwide-container">
            {/* Hero Section */}
            <div className="hero-section" style={{ overflow: "visible" }}>
                <div className="hero-content">
                    <div className="">
                        <h1>Employ professionals with the skills you require</h1>
                        <p>A simple search connects you with independent professionals and teams ready to complete your job.</p>
                    </div>

                    <div className="inputBox">
                        <div style={{ width: "100%" }}>
                            <Select
                                placeholder="Try 'Web Design'"
                                className="select-tag"
                                isMulti
                                value={selectedSkill}
                                onChange={handleSkillChange}
                                options={allskills}
                                onInputChange={handleSkillInputChange}
                                styles={customStyles}
                                menuIsOpen={isTyping}
                                components={{
                                    DropdownIndicator: CustomDropdownIndicator,
                                }}
                            />
                        </div>

                        <div className="searchBtn" style={{ cursor: "pointer" }}>
                            <img src={search} alt="" className="searchIcon" onClick={navigateToFreelancerDetails} />
                        </div>
                    </div>
                    {user?.role === "User" || !user ? (
                        <p className="signup-text">
                            Looking for work?{" "}
                            <a href={!user ? "/signup" : "/matchedHourlyProject/all"} className="signup-link" onClick={() => handleAddEvent("Apply as an independent pro")}>
                                Apply as an independent pro
                            </a>
                        </p>
                    ) : null}
                </div>
                <div className="hero-image">{/* <img src={directContract_1} alt="Person working on laptop" /> */}</div>
            </div>

            <section className="how-it-works">
                <h2>Safe and secure hiring, no matter the scale of the work</h2>
                <div className="steps-container">
                    {steps.map((step, index) => (
                        <div key={index} className="step-item">
                            <div className="step-number">
                                <img src={step.number} alt="" />
                            </div>
                            <div className="content">
                                <h3>{step.title}</h3>
                                <p>{step.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section className="how-it-works">
                <h2>Choose a category to see popular skills for hire</h2>
                <div className="buttons">
                    <button onClick={handleContentChange} className={`${soloTeam === "Solo" ? "" : "active"}`}>
                        Solo professionals
                    </button>
                    <button onClick={handleContentChange} className={`${soloTeam === "Solo" ? "active" : ""}`}>
                        Teams
                    </button>
                </div>

                <p className="otherContent">{soloTeam === "Solo" ? "Bringing on a professional is ideal for expanding your team or embarking on a project." : "Hiring a team is ideal for handling complex tasks that require multiple roles or if you prefer a hands-off approach to managing your project."}</p>
            </section>

            <div className="howoitwork_accordion">
                {soloTeam === "Solo" &&
                    data?.map((categoryItem, index) => (
                        <div key={index} className={`howoitwork_accordion-item ${activeIndices.has(index) ? "active" : ""}`}>
                            <button className="howoitwork_accordion-button" onClick={() => toggleAccordion(index)}>
                                <span className="howoitwork_arrow-icon">
                                    <img src={downArrow} alt="Expand/Collapse" />
                                </span>
                                <div>
                                    <span className="howoitwork_accordion-title">{categoryItem.details.soloProfessionals.length > 0 && categoryItem.category}</span>
                                </div>
                            </button>

                            {activeIndices.has(index) && (
                                <div className="howoitwork_accordion-content">
                                    <h1 className="popularSkills">Popular skills:</h1>
                                    <div className="howoitwork_services-grid">
                                        {/* Solo Professionals section */}
                                        {soloTeam === "Solo" && (
                                            <>
                                                {categoryItem.details.soloProfessionals.map((service, serviceIndex) => (
                                                    <div key={`solo-${serviceIndex}`} className="howoitwork_service-item" onClick={() => navigateToFreelancerDetailsForProfile(service)}>
                                                        {service}
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                {soloTeam === "Team" &&
                    data?.map(
                        (categoryItem, index) =>
                            categoryItem.details.teams?.length > 0 && (
                                <div key={index} className={`howoitwork_accordion-item ${activeIndices.has(index) ? "active" : ""}`}>
                                    <button className="howoitwork_accordion-button" onClick={() => toggleAccordion(index)}>
                                        <span className="howoitwork_arrow-icon">
                                            <img src={downArrow} alt="Expand/Collapse" />
                                        </span>
                                        <div>
                                            <span className="howoitwork_accordion-title">{categoryItem.category}</span>
                                        </div>
                                    </button>

                                    {activeIndices.has(index) && (
                                        <div className="howoitwork_accordion-content">
                                            <h1 className="popularSkills">Popular skills:</h1>
                                            <div className="howoitwork_services-grid">
                                                {/* Teams section */}
                                                {soloTeam === "Team" && (
                                                    <>
                                                        {categoryItem.details.teams.map((service, serviceIndex) => (
                                                            <div key={`team-${serviceIndex}`} className="howoitwork_service-item" onClick={() => navigateToFreelancerDetailsForProfile(service)}>
                                                                {service}
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                    )}
            </div>
        </div>
    );
};

export default HireWorldWide;
