import React from "react";
import "./pagination.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

// Create a styled Tooltip component with larger font size
const LargeTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  '& .MuiTooltip-tooltip': {
    fontSize: '1.5rem', // Increase font size to 16px
    padding: '8px 12px', // Optional: adjust padding to accommodate larger text
  },
});

const PaginationWithTooltip = ({ currentPage, totalPages, onPageChange }) => {

  const getPaginationItems = () => {
    let pages = [];
    let startPage, endPage;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 4;
      } else if (currentPage >= totalPages - 2) {
        startPage = totalPages - 3;
        endPage = totalPages - 1;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    pages.push(
      <a
        key={1}
        className={`page-item ${1 === currentPage ? "active" : "not-active"}`}
        onClick={() => onPageChange(1)}
        style={{ color: 1 === currentPage ? "white" : "#0e709e" }}
      >
        1
      </a>
    );

    if (startPage > 2) {
      pages.push(<span key="start-dots" className="dots">...</span>);
    }

    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== totalPages) {
        pages.push(
          <a
            key={i}
            className={`page-item ${i === currentPage ? "active" : "not-active"}`}
            onClick={() => onPageChange(i)}
            style={{ color: i === currentPage ? "white" : "#0e709e" }}
          >
            {i}
          </a>
        );
      }
    }

    if (endPage < totalPages - 1) {
      pages.push(<span key="end-dots" className="dots">...</span>);
    }

    if (totalPages > 1) {
      pages.push(
        <a
          key={totalPages}
          className={`page-item ${totalPages === currentPage ? "active" : "not-active"}`}
          onClick={() => onPageChange(totalPages)}
          style={{ color: totalPages === currentPage ? "white" : "#0e709e" }}
        >
          {totalPages}
        </a>
      );
    }

    return pages;
  };

  return (
    <div className="pagination">
      <LargeTooltip title="Previous page" placement="top">
        <a
          className="button-prev"
          onClick={() => {
            if (currentPage === 1) {
              return;
            }
            onPageChange(currentPage - 1);
          }}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
            color: currentPage === 1 ? "#a8a7a7" : "#0e709e",
          }}
        >
          <MdKeyboardArrowLeft style={{ fontSize: "20px" }} />
        </a>
      </LargeTooltip>
      {getPaginationItems()}
      <LargeTooltip title="Next page" placement="top">
        <a
          className="button-next"
          onClick={() => {
            if (currentPage === totalPages) {
              return;
            }
            onPageChange(currentPage + 1);
          }}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
            color: currentPage === totalPages ? "#a8a7a7" : "#0e709e",
          }}
        >
          <MdKeyboardArrowRight style={{ fontSize: "20px" }} />
        </a>
      </LargeTooltip>
    </div>
  );
};

export default PaginationWithTooltip;