import React, { useEffect, useRef, useState } from "react";
import { FiGithub } from "react-icons/fi";
import { FaLink } from "react-icons/fa";
import { FaBlog } from "react-icons/fa";
import { CiCircleQuestion, CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { TbNotes } from "react-icons/tb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoCaretDownSharp, IoChatboxEllipsesOutline } from "react-icons/io5";
import { BiMessageSquareDetail } from "react-icons/bi";
import { BsPersonCheck } from "react-icons/bs";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegFolder } from "react-icons/fa";
import { TiFlashOutline } from "react-icons/ti";
import SimpleDialog from "./Employertemplates/ChatDialog";
import { Tooltip } from "@material-tailwind/react";
import { GoProjectRoadmap } from "react-icons/go";

import {
	getAppliedBefore,
	getMonthDuration,
	getMonthYear,
	getTotalWorkExperience,
} from "./utils/timefunctions";
import { Requesttype } from "./utils/constants";
import * as Papa from "papaparse";
import ViewPortfolioprojects from "../Modal/ViewPortfolioprojects";
import { logEvent } from "../../utils/Analytics";

const ApplicationsAll = ({
	setCounts,
	counts,
	filterInput,
	setFilterInput,
	initial,
	filterTrigger,
	setFilterTrigger,
	searchApplicant,
	setSearchApplicant,
	searchTrigger,
	opportunityType,
}) => {
	const [showFull, setShowFull] = useState([]);
	const [All, setAll] = useState(Array.from({ length: 10 }, () => true));
	const [selectedCandidates, setSelectedCandidates] = useState(
		Array.from({ length: 10 }, () => false)
	);
	const [selectAllToggler, setSelectAllToggler] = useState(false);
	const [showOptions, setShowOptions] = useState(false);
	const [openPopup, setOpenPopup] = useState(false);
	const params = useParams();
	console.log("params", params);
	const [jobData, setjobData] = useState(null);
	// const { state } = useLocation();
	const [state, setState] = useState(null);
	const location = useLocation();
	const params2 = new URLSearchParams(location.search);
	const encodedData = params2.get("data");
	const state2 = encodedData
		? JSON.parse(decodeURIComponent(encodedData))
		: null;


	const [showAllSkills, setShowAllSkills] = useState(false);
	const [isopen, setisopen] = useState(false);
	const [displayproject, setdisplayProject] = useState(null);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`/api/employer/getJobInformationInState?id=${state2}`
				);
				setState(response.data);
			} catch (error) {
				console.error("Error fetching job data:", error);
			}
		};

		if (state2) {
			fetchData();
		}
	}, [state2]);
	const openReviewPageInNewTab = (state) => {
		window.open(`/review/${state._id}`);
	};

	const getJobApplications = async (data) => {
		const reqBody = {
			tabs: Requesttype[data],
			filterInput: filterInput,
		};

		console.log(reqBody, "this is sortlisted req body");

		try {
			const { data } = await axios.post(
				"/api/employer/filterAppliedJob",
				{ reqBody },
				{
					headers: {
						"Content-Type": "application/json",
						token: localStorage.getItem("token"),
					},
				}
			);
			console.log(data?.RESULT_DATA?.ApplicationCounts, "count data");
			setjobData(data?.RESULT_DATA?.JobData);
			setCounts(data?.RESULT_DATA?.ApplicationCounts);
			setAll(data?.RESULT_DATA?.AppliedJobData);
			setSelectedCandidates(
				Array.from(
					{ length: data?.RESULT_DATA?.AppliedJobData?.length },
					() => false
				)
			);
			setShowFull(
				Array.from(
					{ length: data?.RESULT_DATA?.AppliedJobData?.length },
					() => false
				)
			);
			// console.log(data?.RESULT_DATA?.AppliedJobData);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		getJobApplications(params?.type);
	}, [searchTrigger, filterInput]);
	console.log(All, "all");
	const handleUpdateStatus = async (status, i) => {
		var reqBody = {
			function: status,
			data: All.filter((items, index) => selectedCandidates[index]).map(
				(items) => items._id
			),
		};

		if (i != -1) {
			reqBody.data = [All[i]._id];
		}

		console.log(reqBody);
		try {
			await axios.post(
				"/api/employer/changeStatusAppliedJob",
				{ reqBody },
				{
					headers: {
						"Content-Type": "application/json",
						token: localStorage.getItem("token"),
					},
				}
			);

			toast.success("Status changed successfully");
			setTimeout(() => {
				window.location.reload();
			}, 2000);
		} catch (error) {
			toast.error("Something went wrong!!");
			console.log(error);
		}
	};

	const handleExportData = async () => {
		try {
			const response = await axios.post(
				"/api/employer/exporttoexcel",
				{
					selectedIds: All.filter(
						(items, index) => selectedCandidates[index]
					).map((items) => items._id),
				},
				{
					headers: {
						"Content-Type": "application/json",
						token: localStorage.getItem("token"),
					},
				}
			);

			const data = response.data.ResultData;
			logEvent("Button", `Clicked export to excel button`, user ? user.role : "Guest");
			
			// Flatten the nested structure
			const flattenedData = data.map((entry) => {
				const {
					_id,
					coverLetter,
					availability,
					assessment,
					status,
					appliedDate,
					job,
					user,
					employer,
					resume,
				} = entry;

				const {
					PersonalDetails,
					Education,
					Job,
					Internship,
					Training,
					Academics,
					Skills,
				} = resume;

				const {
					firstName,
					lastName,
					address,
					phone,
					email,
					_id: personalDetailsId,
				} = PersonalDetails || {};

				const educationDetails =
					Education && Education.length > 0 ? Education[0] : {};

				const skillsArray = Skills ? Skills.map((skill) => skill.name) : [];

				return {
					_id,
					coverLetter,
					availability,
					assessment,
					status,
					appliedDate,
					job,
					user,
					employer,
					firstName,
					lastName,
					address,
					phone,
					email,
					personalDetailsId,
					school: educationDetails.school,
					degree: educationDetails.degree,
					startDate: educationDetails.startDate,
					endDate: educationDetails.endDate,
					performanceScale: educationDetails.performanceScale,
					performance: educationDetails.performance,
					skills: skillsArray.join(", "), // Convert skills array to a comma-separated string
				};
			});

			// Convert flattened data to CSV format using Papaparse
			const csvData = Papa.unparse(flattenedData);

			// Create a Blob with the CSV data
			const blob = new Blob([csvData], { type: "text/csv" });

			// Create a download link and trigger the download
			const link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.download = `ExportedData_${new Date().toISOString()}.csv`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			toast.success("File exported");
		} catch (error) {
			toast.error("Something went wrong!!");
			console.error(error);
		}
	};

	useEffect(() => {
		setSearchApplicant("");
		setFilterInput(initial);
		setFilterTrigger((prev) => prev + 1);
		setSelectedCandidates((prev) => prev.map((i) => false));
		setSelectAllToggler(false);
		window.scrollTo(0, 0);
	}, [params]);

	useEffect(() => {
		setSearchApplicant("");
		getJobApplications(params?.type);
	}, [filterTrigger]);

	useEffect(() => {
		var count = 0;
		selectedCandidates.forEach((candidate) => (count += candidate === true));
		setShowOptions(count > 0);
	}, [selectedCandidates]);

	const truncate = (text, index) => {
		if (showFull[index]) {
			return text;
		} else {
			return text?.substring(0, 150) + "...";
		}
	};

	const handleSelectAll = (e) => {
		const ok = e.target.checked;
		setSelectAllToggler(ok);
		setShowOptions(ok);
		setSelectedCandidates((prev) => prev.map((i) => ok));
	};

	// const handleSearch = (i) => {
	//   return (i.firstName + " " + i.lastName).toLowerCase().includes(searchApplicant.toLowerCase());
	// };
	// const handleSearch = (i) => {
	//   return (
	//     (i.firstName + " " + i.lastName).toLowerCase().includes(searchApplicant.toLowerCase()) ||
	//     i.email.toLowerCase().includes(searchApplicant.toLowerCase())
	//   );
	// };
	// const handleSearch = (i) => {
	//   const fullName = (i.firstName.trim() + " " + i.lastName.trim()).toLowerCase();
	//   const searchInput = searchApplicant.trim().toLowerCase();
	//   return (
	//     fullName.includes(searchInput) ||
	//     i.email.trim().toLowerCase().includes(searchInput)
	//   );
	// };
	const handleSearch = (i) => {
		const firstName = i.firstName ? i.firstName.trim().toLowerCase() : "";
		const lastName = i.lastName ? i.lastName.trim().toLowerCase() : "";
		const email = i.email ? i.email.trim().toLowerCase() : "";

		const fullName = (firstName + " " + lastName).toLowerCase();
		const searchInput = searchApplicant.trim().toLowerCase();

		return fullName.includes(searchInput) || email.includes(searchInput);
	};
	All && All?.sort((a, b) => new Date(b.appliedDate) - new Date(a.appliedDate));

	const navigate = useNavigate();
	const handleChatClick = async (
		userId,
		employerId,
		appliedJobId,
		mainId,
		navigate
	) => {
		try {
			const response = await axios.post(
				"/api/employer/sendMessagebyclickingchaticon",
				{
					sender: employerId,
					receiver: [[mainId, userId]],
					appliedJobId: appliedJobId,
					// message: '' // You can customize the message content
				},
				{
					headers: {
						"Content-Type": "application/json",
						token: localStorage.getItem("token"),
					},
				}
			);

			console.log(response.data.data, "wff");
			if (response.status === 200) {
				const roomId = response.data.data;
				navigate("/message", { state: roomId });
			}
		} catch (error) {
			console.error("Error creating chat room:", error);
			// Handle error (e.g., show a notification to the user)
		}
	};


	const handleAddEvent = (str) => {
		logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
	}
	return (
		<>
			<ViewPortfolioprojects
				isopen={isopen}
				onClose={() => {
					setisopen(false);
				}}
				displayproject={displayproject}
			/>
			<div className="applicationReceivedAll d-flex flex-column gap-5 px-5 py-3 bg-white">
				{params?.type == "All" && (
					<div className="h4" style={{ color: "#484848", fontWeight: "600" }}>
						Showing{" "}
						{All?.filter(
							(i) =>
								searchApplicant == "" || handleSearch(i.resume.PersonalDetails)
						).length || 0}{" "}
						results out of {counts?.ApplicationReceivedCount} applications
					</div>
				)}

				{params?.type == "Shortlisted" && (
					<div className="banner">
						<span>
							{" "}
							To ensure your rest of the hiring experience is smooth, may we
							recommend that you -
						</span>
						<ul>
							<li className="mb-2">
								{jobData?.opportunityType === "Fixed Cost" ? "Always interview the applicants before finalizing - Helps you understand the freelancers better: their availability for the fixed cost project, their understanding of the role, their expectations & communication skills." : "Always interview the applicants before finalizing - Helps you understand the freelancers better: their availability for the hourly project, their understanding of the role, their expectations & communication skills."}

								.
							</li>
							<li>
								Always extend a formal offer letter - And ask them to convey acceptance before starting.
							</li>
						</ul>
					</div>
				)}

				<div className="d-flex gap-3 flex-wrap align-items-center">
					<input
						onChange={handleSelectAll}
						type="checkbox"
						checked={selectAllToggler}
						style={{ width: "16px", height: "16px", outline: "none" }}
					/>
					<div style={{ fontSize: "14px", color: "#484848" }}>Select all</div>
					{showOptions && (
						<>
							{/* <button className="shortcut"> Send assignment</button> */}
							{params?.type != "Ninterested" ? (
								<>
									<button
										onClick={() => setOpenPopup(!openPopup)}
										className="shortcut"
									>
										Message
									</button>
									<button
										onClick={() => handleExportData()}
										className="shortcut"
									>
										Export to Excel
									</button>
									{params?.type !== "Hired" && (
										<>
											{params?.type !== "Shortlisted" && (
												<button
													onClick={() => {handleUpdateStatus("Shortlisted", -1), handleAddEvent("Shortlist")}}
													className="shortcut"
												>
													Shortlist
												</button>
											)}
											<button
												onClick={() => {handleUpdateStatus("Hired", -1), handleAddEvent("Hire")}}
												className="shortcut"
											>
												Hire
											</button>
											<button
												onClick={() => {handleUpdateStatus("NotIntrested", -1), handleAddEvent("Not Interested")}}
												className="shortcut"
											>
												Not interested
											</button>
										</>
									)}
								</>
							) : (
								<>
									<span
										className="px-3 py-2 mr-2"
										style={{
											backgroundColor: "#eee",
											color: "#333",
											fontSize: "14px",
										}}
									>
										Move to:
									</span>
									<button
										onClick={() => {handleUpdateStatus("Application_Sent", -1), handleAddEvent("Application received")}}
										className="shortcut"
									>
										Application received
									</button>
									<button
										onClick={() => {handleUpdateStatus("Shortlisted", -1), handleAddEvent("Shortlisted")}}
										className="shortcut"
									>
										Shortlisted
									</button>
								</>
							)}
						</>
					)}
				</div>
			</div>

			<div className="list-container">
				{All?.length == 0 ? (
					<div style={{ fontSize: "14px", color: "#e5330e", textAlign: "center" }}>
						No applications to show. Please reach out to us in case of any
						queries.
					</div>
				) : (
					<>
						{All?.filter((x) => searchApplicant == "" || handleSearch(x?.resume?.PersonalDetails))?.map((x, index) => (
							<div key={index} className="candidate-container">
								<div className="d-flex align-items-center justify-content-between w-100 px-4 py-3">
									{/*   <span style={{ color: "#008bdc", fontSize: "14px", fontWeight: "500", cursor: "pointer" }}>Know more</span>*/}
									<span
										style={{
											color: "#008bdc",
											fontSize: "14px",
											fontWeight: "500",
											cursor: "pointer",
										}}
									></span>
									<span
										style={{
											color: "#8a8a8a",
											fontSize: "12px",
											fontWeight: "500",
										}}
									>
										{getAppliedBefore(x?.appliedDate)}
									</span>
								</div>
								<div
									style={{
										width: "100%",
										height: "1px",
										backgroundColor: "#ddd",
									}}
								></div>
								<div className="p-3 p-md-4 d-flex flex-column gap-1">
									<div className="d-flex gap-3 align-items-start">
										<div>
											<input style={{ width: "16px", height: "16px", outline: "none", }} checked={selectedCandidates[index]} onChange={(e) => {
												if (selectedCandidates[index]) {
													setSelectAllToggler(false);
												}
												setSelectedCandidates((prev) =>
													prev.map((i, ix) => (ix === index ? !i : i))
												);
											}}
												type="checkbox"
											/>
										</div>
										<div className="candidate-details ">
											<div className="d-flex flex-column flex-md-row justify-content-md-between "  >

												<div style={{ width: "100%" }}>
													<div className="" style={{ display: "flex" }}>
														<div
															style={{
																textAlign: "center", color: `"008BDC`, height: "47px", width: "47px", fontSize: "12px", border: !x?.resume?.PersonalDetails?.photo?.url && "2px solid gray", borderRadius: "50%", paddingTop: x?.resume?.PersonalDetails?.photo?.url ? "0px" : "2px", marginTop: x?.resume?.PersonalDetails?.photo?.url ? "-2px" : "2px", marginRight: "5px", display: "flex", justifyContent: 'center', alignItems: "center"
															}}>
															{/* {user.name.slice(0, 1)} */}
															{x?.resume?.PersonalDetails?.photo?.url ? (
																<img
																	src={x?.resume?.PersonalDetails?.photo?.url}
																	alt="Profile"
																	style={{
																		width: "100%",
																		height: "100%",
																		objectFit: "cover", // Ensures the image covers the div
																		borderRadius: "50%",
																		boxShadow: "0 0 5px rgba(0,0,0,0.2)",
																		border: "2px solid white",
																	}}
																/>
															) : (
																<p style={{ fontSize: "18px", margin: "0rem" }}>
																	{x?.resume?.PersonalDetails?.firstName.slice(
																		0,
																		1
																	)}
																</p>
															)}
														</div>
														<div className="">
															<a
																href={`/previewFreelancer/${x?.user}`}
																target="_blank"
															>
																<h2
																	className=" text-uppercase"
																	style={{ marginBottom: "0px !important" }}
																>
																	{x?.resume?.PersonalDetails?.firstName}{" "}
																	{x?.resume?.PersonalDetails?.lastName}
																</h2>
															</a>

															<p
																style={{ fontSize: "medium", color: "black", marginBottom: "0.5rem", }}>
																<b>
																	{x?.resume?.PersonalDetails?.professionalTitle}
																</b>
															</p>
															{x?.resume?.PersonalDetails?.minimumRate && (
																<p
																	style={{
																		marginTop: "-8px",
																		fontSize: "17px",
																		color: "black",
																	}}
																>
																	<b>$</b>
																	{/* <b>{resumeData.PersonalDetails.minimumRate}</b> */}
																	<b>
																		{x?.resume?.PersonalDetails?.minimumRate}
																		{/* {
                                  // Check if the minimumRate is a number and whether it includes a decimal point
                                  resumeData.PersonalDetails.minimumRate %
                                    1 !==
                                  0
                                    ? resumeData.PersonalDetails.minimumRate
                                    : `${resumeData.PersonalDetails.minimumRate}.00`
                                } */}
																	</b>
																	<b>/hour</b>
																</p>
															)}
														</div>
													</div>
												</div>

												<div className="" style={{ fontSize: "17px", color: "black", width: "100%" }}>
													<div
														className="d-flex justify-content-start justify-content-md-end align-items-md-center"
														style={{ fontSize: "medium" }}
													>
														<h3 className="" style={{ margin: "0px", fontSize: "15px" }}>
															Your Budget:&nbsp;
														</h3>
														<p className=" " style={{ margin: "0px", fontSize: "15px" }}>
															{jobData?.salaryProfile === "Unpaid" && (
																<>Unpaid</>
															)}

															{jobData?.salaryProfile === "Negotiable" && (
																<>
																	${jobData?.salary} {jobData?.salaryMax !== null && ` - $${jobData?.salaryMax}`}{" "}   {`${jobData?.salaryProfile}`}
																</>
															)}
															{jobData?.salaryProfile === "Performance based" && (
																<>
																	${jobData?.salary} {jobData?.salaryMax === null && ` + $${jobData?.incentive}`}{" "}  per {jobData?.incentiveType && `${jobData?.incentiveType}`}
																</>
															)}

															{jobData?.salaryProfile === "Fixed" && (
																<>
																	${jobData?.salary}
																	{jobData?.salaryMax && ` - $${jobData?.salaryMax}`}{" "}
																	{jobData?.opportunityType !== "Hourly" ? "Fixed Cost" : "per hour"}
																</>
															)}

															{!jobData?.salaryProfile && (
																<>
																	${jobData?.salary}{" "}
																	{jobData?.salaryMax !== null && ` - $${jobData?.salaryMax}`}{" "}
																	{jobData?.opportunityType !== "Hourly" ? "Fixed Cost" : "per hour"}
																</>
															)}
														</p>
													</div>

													<div className="d-flex justify-content-start justify-content-md-end align-items-md-center " style={{ fontSize: "medium" }}
													>
														<h3 style={{ margin: "0px", fontSize: "15px" }}>
															Proposed Bid:&nbsp;{" "}
														</h3>{" "}
														<p style={{ margin: "0px", fontSize: "15px" }}>

															${x?.userBid}{" "}
															{jobData?.opportunityType !== "Hourly"
																? `Fixed Cost`
																: `per hour`}
														</p>
													</div>
												</div>


											</div>
											<h5
												className="mb-5 d-flex flex-wrap align-items-center gap-3"
												style={{ color: "#666", fontSize: "12px" }}
											>
												{x?.resume?.PersonalDetails?.address} (Open to relocate){" "}
												<Circle /> Total work experience:{" "}
												{getTotalWorkExperience(
													x?.resume?.Internship,
													x?.resume?.Job
												)}
												<Info
													text={
														<span style={{ fontSize: "14px" }}>
															Includes both Internship and Job experience
														</span>
													}
												/>
											</h5>

											<div className="w-100 d-flex flex-column gap-4">
												{/* Expericne */}
												{(x.resume?.Job?.length > 0 ||
													x.resume?.Internship?.length > 0) && (
														<div className="d-flex gap-2 align-items-start">
															<div className="title">Experience</div>
															<div className="d-flex flex-column gap-4">
																{x?.resume?.Internship?.map((intern) => (
																	<div className="description-main">
																		{intern?.profile} at {intern?.organization}{" "}
																		<Circle />
																		<span className="description">
																			{getMonthYear(intern?.startDate)} -{" "}
																			{intern?.endDate
																				? getMonthYear(intern?.endDate)
																				: "Present"}
																			<Circle />
																			{getMonthDuration(
																				intern?.startDate,
																				intern?.endDate
																					? intern.endDate
																					: new Date()
																			)}{" "}
																			(Internship)
																		</span>
																	</div>
																))}

																{x?.resume?.Job?.slice(0, 2)?.map(
																	(intern, id) => (
																		<div className="description-main">
																			{intern?.profile} at {intern?.organization}{" "}
																			<Circle />
																			<span className="description">
																				{getMonthYear(intern?.startDate)} -{" "}
																				{intern?.endDate
																					? getMonthYear(intern?.endDate)
																					: "Present"}
																				<Circle />
																				{getMonthDuration(
																					intern?.startDate,
																					intern?.endDate
																						? intern.endDate
																						: new Date()
																				)}{" "}
																				(Job)
																			</span>
																			{id == 1 && x?.resume?.Job?.length > 2 && (
																				<span className="description-light">
																					+{x?.resume?.Job?.length - 2} more
																				</span>
																			)}
																		</div>
																	)
																)}
															</div>
														</div>
													)}

												{/* Education */}
												{x.resume?.Education?.length > 0 && (
													<div className="d-flex gap-2 align-items-start">
														<div className="title">Education</div>
														<div>
															{x?.resume?.Education?.slice(0, 2)?.map(
																(edu, id) => (
																	<>
																		<div className="description-main">
																			{edu?.degree} (
																			{new Date(edu?.startDate).getFullYear()} -{" "}
																			{new Date(edu?.endDate).getFullYear()})
																			<Circle />
																			<span className="description">
																				{edu?.performanceScale} -{" "}
																			</span>{" "}
																			{edu?.performance[0] +
																				(edu?.performanceScale ==
																					"Percentage" && "%")}
																		</div>
																		<div className="description-main">
																			<span className="description-light">
																				{edu?.school}
																			</span>
																			{x.resume?.Education?.length > 2 &&
																				id === 1 && (
																					<span className="description-light ml-5">
																						+{x.resume?.Education?.length - 2}{" "}
																						more
																					</span>
																				)}
																		</div>
																	</>
																)
															)}
														</div>
													</div>
												)}

												{/* Portfolio */}
												{x?.resume?.Academics?.length > 0 && (
													<div className="d-flex gap-2 align-items-start">
														<div className="title">Portfolio/work samples</div>
														<div
															className="description-main"
															style={{
																color: "#008bdc",
																fontSize: "18px",
																gap: "28px",
															}}
														>
															{/* <a href="">
                              <FaBlog />
                            </a> */}
															<div
																onClick={() => {
																	setdisplayProject(x.profilePortfolio);
																	setisopen(true);
																}}
															>
																<img
																	src="/portfolio-svgrepo-com.svg"
																	alt="Portfolio"
																	style={{
																		width: "40px",
																		cursor: "pointer",
																		transition: "transform 0.3s ease",
																	}}
																	onMouseEnter={(e) =>
																	(e.currentTarget.style.transform =
																		"scale(1.2)")
																	}
																	onMouseLeave={(e) =>
																	(e.currentTarget.style.transform =
																		"scale(1)")
																	}
																/>
															</div>
															{/* <a href="">
                              <FaLink />
                            </a> */}
														</div>
													</div>
												)}

												{/* Skills */}
												{/* {x.resume?.Skills?.length > 0 && (
                          <div className="d-flex gap-2 align-items-start">
                            <div className="title">
                              Skills <Info text="Self-assessed by applicants" />
                            </div>
                            <div className="description-main">
                              {x?.resume?.Skills?.slice(0, 5)?.map((skill, id) => (
                                <>
                                  <Star ok={skill?.level[0] == "B" ? 1 : skill?.level[0] == "I" ? 2 : 3} skill={skill?.name} />
                                  {id == 4 && x.resume?.Skills?.length > 5 && (
                                    <span className="description-light">+{x.resume?.Skills?.length - 5} more</span>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        )} */}
												{x.resume?.Skills?.length > 0 && (
													<div className="d-flex gap-2 align-items-start">
														<div className="title">
															Skills <Info text="Self-assessed by applicants" />
														</div>
														<div className="description-main">
															{x?.resume?.Skills?.slice(0, 3)?.map(
																(skill, id) => (
																	<Star
																		key={id}
																		ok={
																			skill?.level[0] === "B"
																				? 1
																				: skill?.level[0] === "I"
																					? 2
																					: 3
																		}
																		skill={skill?.name}
																	/>
																)
															)}
															{!showAllSkills &&
																x.resume?.Skills?.length > 3 && (
																	<span
																		className="description-light"
																		onClick={() => setShowAllSkills(true)}
																		style={{ cursor: "pointer" }}
																	>
																		+{x.resume?.Skills?.length - 3} more
																	</span>
																)}
															{showAllSkills &&
																x?.resume?.Skills?.slice(3)?.map(
																	(skill, id) => (
																		<Star
																			key={id}
																			ok={
																				skill?.level[0] === "B"
																					? 1
																					: skill?.level[0] === "I"
																						? 2
																						: 3
																			}
																			skill={skill?.name}
																		/>
																	)
																)}
														</div>
													</div>
												)}

												{/* Cover Letter */}
												<div className="d-flex gap-2 align-items-start">
													<div className="title">
														Coverletter{" "}
														<Info
															text={
																<span>
																	Cover letter
																	<br />
																	Why should you be hired for this role?
																</span>
															}
														/>
													</div>
													<div className="description-main">
														<span className="description">
															<span>
																{truncate(x?.coverLetter, index)}
																<span
																	onClick={() =>
																		setShowFull((prev) =>
																			prev.map((i, ix) =>
																				ix === index ? !i : i
																			)
																		)
																	}
																	style={{
																		color: "#008bdc",
																		cursor: "pointer",
																		marginLeft: "4px",
																	}}
																>
																	See {showFull[index] ? "less" : "more"}
																</span>
															</span>
														</span>
													</div>
												</div>

												{/* Availiblity */}
												<div className="d-flex gap-2 align-items-start">
													<div className="title">
														Availiblity{" "}
														<Info
															text={
																<span>
																	Availiblity
																	<br />
																	Are you available for 6 months, starting
																	immediately, for a full-time work from home
																	internship? If not, what is the time period
																	you are available for and the earliest date
																	you can start this internship on?
																</span>
															}
														/>
													</div>
													<div className="description-main">
														<span className="description">
															{x?.availability
																? "Yes, I am available to join Immediately."
																: `I will be available to join ${x?.specifyAvailability}.`}
														</span>
													</div>
												</div>

												{/* Task Buttons */}
												<div className="d-flex align-items-center justify-content-between">
													<div className="description-main gap-5">
														<a href={`/review/${x._id}`} target="_blank">
															View full application
														</a>

														{/*  <a href="" className="d-flex gap-2 align-items-center">
                              {" "}
                              <TbNotes /> Add notes
                              </a> */}
													</div>

													{!showOptions && params?.type !== "Hired" && (
														<div className="description-main">
															{params?.type !== "Ninterested" && (
																<button
																	onClick={() => {handleUpdateStatus("NotIntrested", index), handleAddEvent("Not interested")
																	}}
																	style={{
																		color: "#fc2d2d",
																		backgroundColor: "#fff2eb",
																	}}
																>
																	Not interested
																</button>
															)}

															{params?.type !== "Shortlisted" &&
																params?.type !== "Ninterested" && (
																	<button
																		onClick={() =>
																			{handleUpdateStatus("Shortlisted", index),handleAddEvent("Shortlisted")}
																		}
																		style={{
																			color: "#008bdc",
																			backgroundColor: "#eafcff",
																		}}
																	>
																		Shortlist
																	</button>
																)}

															{params?.type !== "Hired" &&
																params?.type !== "Ninterested" && (
																	<button
																		onClick={() =>
																			{handleUpdateStatus("Hired", index), handleAddEvent("Hire")}
																		}
																		style={{
																			color: "#008bdc",
																			backgroundColor: "#eafcff",
																		}}
																	>
																		Hire
																	</button>
																)}

															{params?.type != "Ninterested" ? (
																<Dropdown1
																	handleUpdateStatus={handleUpdateStatus}
																	index={index}
																/>
															) : (
																<Dropdown2
																	handleUpdateStatus={handleUpdateStatus}
																	index={index}
																/>
															)}
															<IoChatboxEllipsesOutline
																className="chatIconOfemp2"
																style={{
																	fontSize: "28px",
																	cursor: "pointer",
																	color: "#00a5ec",
																	marginLeft: "3px",
																}}
																onClick={() =>
																	handleChatClick(
																		x.user,
																		state?.employer,
																		state?._id,
																		x._id,
																		navigate
																	)
																}
															/>
														</div>
													)}

													{params?.type == "Hired" && (
														<div className="description-main">
															{/* <a href="#">Open chat</a>*/}
															<IoChatboxEllipsesOutline
																className="chatIconOfemp2"
																style={{
																	fontSize: "28px",
																	cursor: "pointer",
																	color: "#00a5ec",
																	marginLeft: "3px",
																}}
																onClick={() =>
																	handleChatClick(
																		x.user,
																		state?.employer,
																		state?._id,
																		x._id,
																		navigate
																	)
																}
															/>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</>
				)}
			</div>

			<SimpleDialog
				candidates={All.filter((items, index) => selectedCandidates[index]).map(
					(i) => i
				)}
				appliedJobId={state?._id}
				employerId={state?.employer}
				open={openPopup}
				onClose={() => setOpenPopup(false)}
			/>
		</>
	);
};

export default ApplicationsAll;

const Circle = () => {
	return (
		<div
			style={{
				width: "4px",
				height: "4px",
				background: "#666",
				borderRadius: "100%",
			}}
		></div>
	);
};

const Star = ({ ok, skill }) => {
	return (
		<div className="d-flex align-items-center gap-3 mr-3">
			{Array.from({ length: ok }, () => (
				<FaStar color="#ffae00" size={14} />
			))}
			{Array.from({ length: 3 - ok }, () => (
				<CiStar color="#bbb" size={14} />
			))}

			<span className="description">{skill}</span>
		</div>
	);
};

const Dropdown1 = ({ text, options, handleUpdateStatus, index }) => {
	const dropdownRef = useRef();
	const [dropdown, setDropdown] = useState(false);

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdown(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const navigate = useNavigate();
	return (
		<>
			<div ref={dropdownRef} style={{ position: "relative" }}>
				{/* <button onClick={() => setDropdown(!dropdown)} style={{ color: "white", backgroundColor: "#00a5ec" }}>
          Next Steps <IoCaretDownSharp style={{ transform: `${dropdown ? "rotate(180deg)" : "rotate(0deg)"}` }} />
        </button>*/}

				{dropdown && (
					<div className="dropdown">
						{/* <span>
              <TbNotes /> Send assignment
            </span>
            <span>
              <LuCalendarClock />
              Schedule Interview
            </span> */}
						<span onClick={() => navigate("/message")}>
							<BiMessageSquareDetail /> Start chat
						</span>
						<span onClick={() => handleUpdateStatus("Hired", index)}>
							<BsPersonCheck />
							Hire
						</span>
					</div>
				)}
			</div>
		</>
	);
};

const Dropdown2 = ({ text, options, handleUpdateStatus, index }) => {
	const dropdownRef = useRef();
	const [dropdown, setDropdown] = useState(false);

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdown(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);
	 const handleAddEvent = (str) => {
			logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
		}
	return (
		<>
			<div ref={dropdownRef} style={{ position: "relative" }}>
				<button
					onClick={() => setDropdown(!dropdown)}
					style={{ color: "white", backgroundColor: "#00a5ec" }}
				>
					Change status to{" "}
					<IoCaretDownSharp
						style={{
							transform: `${dropdown ? "rotate(180deg)" : "rotate(0deg)"}`,
						}}
					/>
				</button>

				{dropdown && (
					<div className="dropdown">
						{/* <span>
              <TbNotes /> Send assignment
            </span>
            <span>
              <LuCalendarClock />
              Schedule Interview
            </span> */}
						<span onClick={() => {handleUpdateStatus("Application_Sent", index),handleAddEvent("Application received ")}}>
							<FaRegFolder /> Application received
						</span>
						<span onClick={() => {handleUpdateStatus("Shortlisted", index),handleAddEvent("Shortlisted")}}>
							<TiFlashOutline />
							Shortlisted
						</span>
					</div>
				)}
			</div>
		</>
	);
};

const Info = ({ text }) => {
	return (
		<>
			<Tooltip
				content={
					<div
						style={{
							backgroundColor: " black",
							color: "white",
							padding: "10px",
							borderRadius: "4px",
							fontSize: "12px",
							maxWidth: "250px",
							wordBreak: "break-word",
						}}
					>
						{text}
					</div>
				}
			>
				<span style={{ cursor: "pointer" }}>
					<CiCircleQuestion size={15} />
				</span>
			</Tooltip>
			<ToastContainer position="top-center" autoClose={10000} />
		</>
	);
};
