import React from "react";
import "./css/AboutUs.scss";
const Story = () => {
    return (
        <div className="story-wrapper" style={{ backgroundColor: "white" }}>
            <h4 className="subHeading">Post a Project</h4>
            <p className="storyContent">Share the details of your project by posting it on Workcroft. We'll swiftly connect you with skilled freelancers tailored to your needs.</p>
            <br />
            <br />
            <h4 className="subHeading">Interview</h4>
            <p className="storyContent">Explore proposals, review freelancer profiles, and check their ratings. Compare candidates, conduct interviews, and select the perfect match.</p>
            <br />
            <br />
            <h4 className="subHeading">Hire</h4>
            <p className="storyContent">Assign your project to the chosen freelancer, track progress, and release payments upon successful completion of milestones or the entire project.
            </p>
            <br />
            <br />
        </div>
    );
};

export default Story;
