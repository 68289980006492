import React, { useEffect } from "react";
import { howtofindwork_1, howtofindwork_2, howtofindwork_3, howtofindwork_4, howtofindwork_5, howtofindwork_6 } from "../../assets";
import "./css/HowToFindWork.scss"
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { logEvent } from "../../utils/Analytics";
const HowToFindWork = () => {
    useEffect(() => {
        window.scroll(0,0);
    },[])

    const {user} = useSelector((state) => state.user)
    const steps = [
        {
            icon: `${howtofindwork_2}`,
            title: "Create your profile (it's free)",
            description: "Craft a compelling title and client-centric overview to enhance your chances of landing the ideal job. Highlight your work history, skills, and include a professional photo. To further differentiate yourself, consider adding extras like an introduction video to create a standout profile.",
        },
        {
            icon: `${howtofindwork_3}`,
            title: "Explore ways to earn",
            description: "Earn through diverse avenues: bid for jobs, pitch projects, and discuss your sought-after skills with our recruiters to find opportunities that match your passions and career objectives. Utilize all three methods to establish a steady workflow and expand your professional network.",
        },
        {
            icon: `${howtofindwork_4}`,
            title: "Get paid securely",
            description: "Select your preferred payment method. Our fixed-price protection releases client payments at project milestones, while hourly protection bills clients on a weekly basis. No matter how you choose to work, our service fees remain consistent. Spend less time chasing payments and more time earning.",
        },
    ];

    const handleAddEvent = (str) => {
            logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
        }
    return (
        <div className="howtofindwork-container">
            {/* Hero Section */}
            <div className="howtofindwork-hero-section">
                <div className="hero-content">
                    <div className="">
                        <h1>Operate according to your preferences</h1>
                        <p>Discover the ideal opportunities for you with top clients on the world's largest marketplace for work.</p>

                        {!user && <a href="/signup" onClick={() => handleAddEvent("Create Profile")}><button className="primary-btn">Create Profile</button></a>}
                    </div>
                    <p style={{marginTop:"1rem"}}>Professionals rate clients</p>
                </div>
                <div className="hero-image">
                    <img src={howtofindwork_1} alt="Person working on laptop" />
                </div>
            </div>


            <div className="howtofindwork-how-it-works">
                <h2 className="howtofindwork-title">How it works</h2>

                <div className="steps">
                    {steps.map((step, index) => (
                        <div key={index} className="step">
                            <div className="step-icon"><img src={step.icon} alt="" /></div>
                            <div className="howtofindwork-step-content">
                                <h3 className="step-title">{step.title}</h3>
                                <p className="step-description">{step.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                {!user && <a href="/signup"onClick={() => handleAddEvent("Create Profile")} ><button className="create-profile-btn">Create profile</button></a>}
            </div>
            <div className="howtofindwork-how-it-works" style={{marginTop:"4rem"}}>
                <h2 className="howtofindwork-title">Explore ways to earn</h2>
                <hr />
            </div>


            <div className="howtofindwork-hero-section-2">
                <div className="hero-content-2">
                    <div className="">
                        <h1>Discover your next opportunity</h1>
                        <p>Explore Talent Marketplace™ for the hourly or fixed-price work you seek. Submit a proposal, set your rate, and showcase your skills and capabilities. Stand out by sharing your unique approach and offering a rapport-building interview.</p>
                        <Link to="/matchedHourlyProject/all" onClick={() => handleAddEvent("Find jobs")}>
                        <button className="primary-btn">Find Jobs</button>
                        </Link>
                    </div>
                </div>
                <div className="hero-image-2">
                    <img src={howtofindwork_5} alt="Person working on laptop" />
                </div>
            </div>

            <section className="reasons">
                <div className="reasons__content">
                    <div className="reasons__image">
                        <img src={howtofindwork_6} alt="Team meeting in modern office space" />
                    </div>
                    <div className="reasons__text">
                        <h2>How payments work</h2>
                        <p>Whether you're paid by the hour or on a fixed-price contract, all completed work is protected with payment assurance.</p>
                        <div className="reasons__list">
                            <div className="reason">
                                <h3>All consolidated in one location</h3>
                                <p>Generate invoices and monitor your earnings seamlessly on Workcroft.</p>
                            </div>

                            <div className="reason">
                                <h3>Select your preferred payment method</h3>
                                <p>Utilize the method that suits you best—whether it's direct deposit, PayPal, Payoneer, wire transfer, or others available.</p>
                            </div>

                            <div className="reason">
                                <h3>No fees until your work is finished</h3>
                                <p>We charge connection fees that are necessary for submitting bid proposals and increasing your profile visibility to potential employers.</p>
                            </div>
                            <Link to="/learn-more" onClick={() => handleAddEvent("Learn more about service fees")}>Learn more about service fees.</Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HowToFindWork;
