import React, { useState, useEffect } from 'react'
import Spinner from '../Layout/Spinner'
// import Logo from '../../assets/images/logo.png'
import Logo from '../../assets/images/Workcroft.png'
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import "./css/Plan.scss";
import { useDispatch } from 'react-redux';
import { loginUserForAdminCandidate } from '../../actions/userActions';

function ForgotPassword() {

   const [role, setRole] = useState("");
    const navigate = useNavigate();
    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({ otp: "" });
    const [loading2, setLoading2] = useState(false);

    const dispatch = useDispatch()
    const location = useLocation();
    const from = location.state?.from || '/';

    useEffect(() => {
      if (state && state.role) {
        setRole(state.role);
      }
    }, [state]);



    const handleResendOTP = async()=>{
      if(state.role){
      setLoading2(true);
      const { data } = await axios.post("/api/user/otp", state);
      if (data.status === 404) {
        setLoading2(false);
        toast.error("This email is already used by another user", {
          style: {
            padding: '18px',
          }
        });
      }
      else {
        setLoading2(false);
        toast.success("Otp is now sent to your email");
        otp();
      }
    }
    else{
      setLoading2(true);
      const { data } = await axios.post("/api/user/otpfp", state);
        setLoading2(false);
        otp();
      }
    }
    

    const handleUserChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
      };

    const otp = async()=>  {
       const res = axios.post("/api/user/getotp",state);
       const logData =  res;
    const otpD = ((await logData).data.OTP);
    return otpD;
    
       }


    const signup = async () => {



      if(state.name){
        setLoading(true);
        if(user.otp==""){
            setLoading(false);
            toast.error("Please enter the OTP!!")
        }
        else if(user.otp == await otp()){
          try {
            const { data } = await axios.post("/api/user/signup", state);
            console.log(data);
            dispatch(loginUserForAdminCandidate(data.userId, "User"));
            if (data?.role === "User"){
              localStorage.setItem('userType', "User");
              localStorage.setItem('userLoggedIn', true);
              localStorage.setItem('id', data.userId);
              const basicDetails = {
                name: data?.name,
                email: data.email,
              };
              localStorage.setItem("basicDetails", JSON.stringify(basicDetails));               
              
            }
            else if (data?.role === "Employer"){
              localStorage.setItem('userType', "Employer");
              localStorage.setItem('id', data.userId);
              localStorage.setItem('userLoggedIn', true);
              const basicDetails = {
                name: data?.name,
                email: data.email,
              };
              localStorage.setItem("basicDetails", JSON.stringify(basicDetails));               
            }
            setLoading(false);
            toast.success("Account Created Successfully!", {
              style: {
                padding: '18px',  
              }
            });  
            const timer = setTimeout(() => {
                // navigate('/login', { state: { role: role } }); 
                if(data?.role === "User"){
                  // navigate("/")
                  navigate(from, { replace: true });
                } 
                else if(data?.role === "Employer"){
                  navigate("/employerApplication/Internship")
                }
              }, 2000);
            
        timer();
      
          } catch (error) {
            console.log(error.response.data);
            setLoading(false);
            toast.success(error.response.data, {
              style: {
                padding: '18px',  
              }
            });
          
          }
    
          
        }
        else{
            setLoading(false);
            toast.error("Wrong OTP entered!!")
        }
      }

      else{
        setLoading(true);
        if(user.otp==""){
            setLoading(false);
            toast.error("Please enter the OTP!!")
        }
        else if(user.otp == await otp()){
          try {
            
            setLoading(false);

          navigate('/password/reset',{ state })
          } catch (error) {
            setLoading(false);
            toast.success(error.response.data, {
              style: {
                padding: '18px',  
              }
            });
          
          }
    
          
        }
        else{
            setLoading(false);
            toast.error("Wrong OTP entered!!")
        }

      }
   
      
      };

      useEffect(() => {
        const handleKeyPress = (event) => {
          if (event.key === 'Enter') {
            const myButton = document.querySelector('.verifyOTP');
            if (myButton) {
              myButton.click();
            } else {
              console.error('Button not found');
            }
          }
        };
        // Attach the event listener to the document
        document.addEventListener('keydown', handleKeyPress);
        // Clean up the event listener when the component is unmounted
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
      }, []);

   
    return (

       
        <>

      
        <div className="login-component" >
            <div className="form-box">
                <div className="form">
                    <div style={{ display: "flex", justifyContent: "center", paddingBottom: "3rem" }}>
                        <img src={Logo} alt="logo" style={{ width: "100px", height: "auto" }} />
                    </div>
                    <p className='text-center' style={{fontSize: "25px",  fontWeight: "bold"}}>OTP Verification</p>
              <p className='text-center'> Enter the OTP you received at<br></br><b style={{ color: "#2874A6" }}>{state.email}</b></p>

              <div className="input-box">

                <input
                  type="text"
                  name="otp"
                  id="otp"
                  onChange={handleUserChange}
                  value={user.otp}
                  maxLength="6"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 6)"
                  pattern="[0-9]{6}"
                  title="Please enter a 6-digit number"
                />



                    </div>
                   <p onClick={handleResendOTP} 
                   className='resentOTP'
                   style={{textAlign: "end", fontSize: "14px", marginTop: "-5px", cursor: "pointer"}}
                   >{loading2? "Loading..": "Resend OTP"}</p>
                
                    { /* <div className="input-box">
                        <label htmlFor="password">Password</label>
                        <Link to='/forgot' className='forgot'>Forgot Password?</Link>
                        <input type="password" name="password" value={user.password} onChange={handleUserChange} id="password" />
                    </div> */}
                  
                    <button onClick={signup} className="btn btn-primary  verifyOTP" style={{ width: "100%", marginTop: "1rem" }}>
                        {loading ? <Spinner /> : <span>Verify OTP</span>}
                    </button>
                    <p style={{marginTop: "20px", textAlign: "center", marginBottom: "-5px"}}>OTP has been sent. Kindly check inbox, or junk folder or spam folders. </p>
                </div>
            </div>
        </div>
        <ToastContainer
      position="top-center"
      autoClose={10000}
      />
        </> )
}

export default ForgotPassword