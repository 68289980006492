// EnterprisePackage.jsx
import React from 'react';
import "../css/LandingPageNew.scss";
import { Enterperise } from '../../../assets';
import { bag, editLight, headphone } from '../../../assets';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../utils/Analytics';

const EnterprisePackage = () => {
  const { user } = useSelector((state) => state.user);

  const handleAddEvent = () => {
    logEvent("Button", "Clicked Learn more", user ? user.role : "Guest");
  }
  return (
    <section className="enterprise-card">
      <div className="content-section">
        <span className="package-label">Enterprise Package</span>

        <h1>This is how</h1 >
        <h1 className='diffStyle'>reputable companies</h1>
        <h1 className='diffStyle'>find their ideal partners.</h1>


        <p className="description">
          Access the top 1% of talent on Workcroft, alongside a complete suite of hybrid
          workforce management tools. Experience the future of innovation today.
        </p>

        <div className="features">
          <div className="feature">
            <img src={editLight} alt="" className='icon' />
            <span>Access skilled professionals to fill your expertise needs.</span>
          </div>

          <div className="feature">
            <img src={bag} alt="" className='icon' />
            <span>Take control of your workflow: hire, categorize, and compensate your talent.</span>
          </div>

          <div className="feature">
            <img src={headphone} alt="" className='icon' />
            <span>Partner with Workcroft for complete end-to-end support in managing your projects.</span>
          </div>
        </div>

        <Link to="/enterprise" style={{ color: "#16A085" }} onClick={handleAddEvent}>
          <button className="learn-more">
            Learn more
          </button>
        </Link>
      </div>

      <div className="image-section">
        <img
          src={Enterperise}
          alt="Professional in a chair with papers flying"
          className="hero-image"
        />
      </div>
    </section>
  );
};

export default EnterprisePackage;