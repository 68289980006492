import React, { useEffect, useState, useRef } from "react";
import "./css/editProfile123.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiUsers } from "react-icons/fi";
import { CgOrganisation } from "react-icons/cg";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../actions/userActions";
import { HiOutlinePencilAlt } from "react-icons/hi";
import PhotoEditorModal from "./PhotoEditorModal";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../../utils/Analytics";
const EditProfile = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const [countryCode, setCountryCode] = useState("91");
    const [userImage, setUserImage] = useState("");
    const [updateLoader, setUpdateLoader] = useState(false);
    const [isEditingPhoto, setIsEditingPhoto] = useState(false);
    const [editorData, setEditorData] = useState(null);
    const [navigateOnPrevPath, setNavigateOnPrevPath] = useState(false);

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0);
    }, []);
    const handlePhotoChange = async (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        // reader.readAsDataURL(file);
        // // reader.onloadend = () => {
        // //   setPersonalDetails({
        // //     ...personalDetails,
        // //     photo: reader.result, // base64 string
        // //   });
        // // };
        // // console.log(reader.result,"result")
        // // if (file) {
        // //   reader.readAsDataURL(file);
        // // }

        // reader.onload = () => {
        //   if (reader.readyState === 2) {
        //     if (reader.result) {
        //       setPersonalDetails({
        //         ...personalDetails,
        //         photo: reader.result,
        //       });
        //     }
        //   }
        // };
        reader.onload = (event) => {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.src = event.target.result;

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // Set the canvas dimensions to a fixed size (e.g., 150x150)
                const maxWidth = 521;
                const maxHeight = 352;
                // const maxWidth = 300;
                // const maxHeight = 300;

                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height *= maxWidth / width));
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width *= maxHeight / height));
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                // ctx.imageSmoothingEnabled = false;
                // ctx.imageSmoothingQuality = 'high';

                ctx.drawImage(img, 0, 0, width, height);
                // const format = file.type;
                const format = "image/jpeg";
                const resizedDataUrl = canvas.toDataURL(format, 0.95); // You can adjust the quality here
                // setImageWidth(width);
                // setImageHeight(height);
                setUserImage(resizedDataUrl);
                //console.log(resizedDataUrl, "url");
                setAvatar(resizedDataUrl);
            };
        };

        reader.readAsDataURL(file);
    };
    const navigate = useNavigate();

    const [users, setUser] = useState({
        name: "",
        email: "",
        phone: "",
        city: "",
    });

    const [avatar, setAvatar] = useState("");

    const handleUserChange = (e) => {
        setUser({
            ...users,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        setUser(user && user);
        setUserImage(user?.avatar?.url);
    }, [user]);
    // console.log(avatar, "user avatar");

    // const handleImageChange = (e) => {
    //   const file = e.target.files[0];

    //   const Reader = new FileReader();
    //   Reader.readAsDataURL(file);

    //   Reader.onload = () => {
    //     if (Reader.readyState === 2) {
    //       setAvatarPrev(Reader.result);

    //       setAvatar(Reader.result);
    //     }
    //   };
    // };

    const handleUpate = async (e) => {
        console.log("call");

        e.preventDefault();
        if (!users.name) {
            toast.error("Name is required!!");
        } else if (!users.email) {
            toast.error("Email is required!!");
        } else if (!users.phone) {
            toast.error("Phone is required!!");
        } else if (users?.phone.length < 7 || users?.phone.length > 16) {
            toast.error("Please confirm your phone number");
        } else if (!users.city) {
            toast.error("City is required!!");
        } else {
            try {
                setUpdateLoader(true);
                const { data } = await axios.post("/api/user/updateuser", {
                    name: users.name,
                    email: users.email,
                    phone: users.phone,
                    city: users.city,
                    avatar: avatar,
                });
                //console.log(data, "hgsfhsdiyewiourewih");
                toast.success("User Updated Successfully!", {
                    style: {
                        padding: "18px",
                    },
                });
                setNavigateOnPrevPath(true)
                dispatch(loadUser());
                setUpdateLoader(false);
            } catch (error) {
                setUpdateLoader(false);
                toast.error(error.response.data, {
                    style: {
                        padding: "18px",
                    },
                });
            }
        }
    };
    const prevPath = sessionStorage.getItem('prevPath')


    useEffect(() => {
        let prevPath1 = prevPath;
        if (user && (user.name && user.email && user.city && user.phone)) {

            if ((user && (user.organisationName && user.organisationDescription)) && navigateOnPrevPath) {
                setNavigateOnPrevPath(false)
                sessionStorage.removeItem('prevPath')
                navigate(prevPath1)

            } else if (navigateOnPrevPath) {
                setNavigateOnPrevPath(false)
                navigate("/myprofile/company")

            }
        }
    }, [navigateOnPrevPath, user])
    //console.log(user);
    const [isProfileComplete, setIsProfileComplete] = useState(false);
    const [isCompanyProfileComplete, setIsCompanyProfileComplete] = useState(false);

    useEffect(() => {

        if (!user) return;
        // Check if profile is complete
        if (user?.name && user?.email && user?.city && user?.phone) {
            setIsProfileComplete(true);

        }
        const isCompanyProfileComplete = user.organisationDescription && user.organisationName && user.organisationURL;
        if (isCompanyProfileComplete) {
            setIsCompanyProfileComplete(true);
        }


    }, [user]);
    useEffect(() => {

        // Check if company profile is complete
        const isCompanyProfileComplete = user?.organisationDescription && user?.organisationName && user?.organisationURL;
        if (isCompanyProfileComplete) {
            setIsCompanyProfileComplete(true);
        }

    }, [user]);
    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
    }
    return (
        <>
            <div
                style={{
                    backgroundColor: "rgba(224, 220, 243, 0.212)",
                    height: "100%",
                    width: "100%",
                    paddingTop: "50px",
                    paddingBottom: "50px",
                }}
            >
                <div className="profile-section123">
                    {/* dp here */}
                    <div className="d-flex align-items-center justify-content-center">

                        <a href="/myProfile" onClick={() => handleAddEvent("Employer profile")}>
                            <div className={`${isProfileComplete ? 'logo' : 'notactive'}`}> <FiUsers /> </div>
                        </a>

                        <div className={`${isProfileComplete ? 'activeBar' : 'notactiveBar'}`} ></div>

                        <a href="/myProfile/company" onClick={() => handleAddEvent("Company profile")}>
                            <div className={`${isCompanyProfileComplete ? 'logo' : 'notactive'}`}> <CgOrganisation /> </div>
                        </a>

                    </div>

                    <div className="d-flex align-items-center justify-content-center gap-5 mt-3">
                        <span>Personal Details</span>
                        <span>Organization Details</span>
                    </div>

                    {/* Personal Details Section */}

                    {<></>}
                    <h2 className="my-5" style={{ fontSize: "30px" }}>
                        Personal Details
                    </h2>

                    <PhotoEditorModal
                        show={isEditingPhoto}
                        handleClose={setIsEditingPhoto}
                        handleSave={(data) => {
                            setAvatar(data);
                            setUserImage(data);
                        }}
                        editorData={editorData}
                    />

                    <input
                        type="file"
                        id="photo-upload"
                        style={{ display: "none" }}
                        onChange={handlePhotoChange}
                        accept=".jpeg,.jpg,.png,.gif,.bmp"
                        multiple={false}
                        data-show-preview="false"
                        data-show-upload="false"
                        aria-invalid="false"
                        capture="camera"
                    />

                    <form className="w-100 d-flex flex-column" onSubmit={handleUpate}>
                        <div className="sub-section123">
                            <div className="details w-100">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",

                                        position: "relative",
                                    }}
                                >
                                    <div style={{ position: "relative" }}>
                                        <div
                                            style={{
                                                width: "120px",
                                                height: "120px",
                                                borderRadius: "50%",
                                                boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                                border: "2px solid white",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                backgroundColor: "#b6b6b8",

                                                overflow: "hidden",
                                            }}
                                        >
                                            <>
                                                {userImage ? (
                                                    <img
                                                        src={userImage}
                                                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                                    />
                                                ) : (
                                                    <p
                                                        style={{
                                                            fontSize: "45px",
                                                            fontWeight: "bold",
                                                            marginTop: "15%",
                                                        }}
                                                    >
                                                        {user?.name?.charAt(0)}
                                                    </p>
                                                )}
                                            </>
                                        </div>
                                        <div
                                            onClick={() => {
                                                document.getElementById("photo-upload").click()
                                                handleAddEvent("Upload profile")
                                            }
                                            }
                                            style={{
                                                position: "absolute",
                                                bottom: "10%", // Adjust to your preference
                                                right: "-12px", // Adjust to your preference
                                                backgroundColor: "white",
                                                borderRadius: "50%",
                                                padding: "5px",
                                                cursor: "pointer",
                                                zIndex: 10, // Ensure the icon is above the photo border
                                                boxShadow: "0 0 5px rgba(0,0,0,0.2)", // Optional: add shadow for better visibility
                                                border: "2px solid white",
                                            }}
                                        >
                                            <HiOutlinePencilAlt
                                                style={{ color: "#2092c7", fontSize: "21px" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <p
                                        onClick={() => {
                                            setEditorData(avatar ? avatar : user?.avatar?.url);
                                            setIsEditingPhoto(true);
                                        }}
                                        className="btn btn-secondary"
                                    >
                                        Edit Photo
                                    </p>
                                </div>
                                <div className="title">Full Name</div>
                                <input
                                    required
                                    style={{
                                        backgroundColor: "white",
                                        width: "100%",
                                        border: "1px solid #ddd",
                                    }}
                                    type="text"
                                    value={users?.name}
                                    name="name"
                                    onChange={handleUserChange}
                                    onMouseOver="this.style.border='1px solid #00a5ec'"
                                    onMouseOut="this.style.border='1px solid #ddd'"
                                />
                            </div>

                            <div className="details w-100">
                                <div className="title">Email</div>
                                <input
                                    style={{ width: "100%", border: "1px solid #ddd" }}
                                    type="email"
                                    placeholder="Email"
                                    value={users?.email}
                                    name="email"
                                    onChange={handleUserChange}
                                    readOnly
                                    onMouseOver="this.style.border='1px solid #00a5ec'"
                                    onMouseOut="this.style.border='1px solid #ddd'"
                                />
                            </div>

                            <div className="details w-100">
                                <div className="title">City</div>
                                <input
                                    required
                                    style={{
                                        backgroundColor: "white",
                                        width: "100%",
                                        border: "1px solid #ddd",
                                    }}
                                    type="text"
                                    placeholder="City"
                                    value={users?.city}
                                    name="city"
                                    onChange={handleUserChange}
                                    onMouseOver="this.style.border='1px solid #00a5ec'"
                                    onMouseOut="this.style.border='1px solid #ddd'"
                                />
                            </div>

                            <div className="details w-100">
                                <div className="title">Mobile Number</div>
                                <div className="d-flex align-items-center justify-content-center gap-2">
                                    <div className="input-text2-container w-25 align-items-center">
                                        <p style={{ marginTop: "7px", backgroundColor: "white" }}>
                                            +
                                        </p>
                                        <input
                                            type="text"
                                            className="input-text2"
                                            placeholder="Enter country code "
                                            value={countryCode}
                                            style={{
                                                backgroundColor: "white",
                                                borderColor: "white",
                                                paddingLeft: "0px",
                                            }}
                                            onChange={(e) => {
                                                if (/^\d*$/.test(e.target.value)) {
                                                    setCountryCode(e.target.value);
                                                }
                                            }}
                                            maxLength={3}
                                        />
                                    </div>

                                    <input
                                        required
                                        minLength={10}
                                        maxLength={10}
                                        style={{
                                            backgroundColor: "white",
                                            border: "1px solid #ddd",
                                            paddingTop: "7px",
                                            paddingBottom: "10px",
                                            marginTop: "2px",
                                        }}
                                        type="number"
                                        className="w-50"
                                        value={users?.phone}
                                        placeholder="Enter phone number"
                                        name="phone"
                                        onChange={handleUserChange}
                                        onMouseOver="this.style.border='1px solid #00a5ec'"
                                        onMouseOut="this.style.border='1px solid #ddd'"
                                    />
                                    <span
                                        className="w-25 d-flex align-items-center gap-1"
                                        style={{ color: "#2fb212" }}
                                    >
                                        <MdOutlineVerifiedUser /> Verified
                                    </span>
                                </div>
                            </div>
                        </div>
                        <button
                            type="submit"
                            disabled={updateLoader}
                            className="submit-btn123"
                            onClick={() => handleAddEvent("Update employer profile")}
                        >
                            {updateLoader ? "Updating..." : "Done"}
                        </button>
                    </form>

                    {/* Organisation Details Section .....*/}
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
};

export default EditProfile;
