import React, { useEffect, useState } from "react";
import "./PreviewJob.scss";
import "./Preview.css";
import { calculateAverage, calculateDaysDifference, determineBgColor, determineColor, findLargestBid, findSmallestBid, formatDate, formatDate2, getRangeApplicants, timeAgo } from "../../utils/helper";
import circle from "../../assets/images/circle.png";
import money from "../../assets/images/money.png";
import calendar from "../../assets/images/calendar.png";
import game from "../../assets/images/game.png";
import arrowup from "../../assets/images/Arrowup.jpg";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { FiUsers } from "react-icons/fi";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import { LiaQuestionCircleSolid } from "react-icons/lia";
import { MONTH_DETAILS } from "../data";
import { MdOutlineCheckCircle } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loadUser } from "../../actions/userActions";
import toast from "react-hot-toast";
import { logEvent } from "../../utils/Analytics";
import { useSelector } from "react-redux";
const ProjectDetailsCard = ({ state2, user, activityData, setShowModal, isApply, appliedData }) => {
    const userType = localStorage.getItem("userType");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentDate = new Date();
    const [filled, setFilled] = useState(false);
    // Get date 30 days from now
    const next30Days = new Date();
    next30Days.setDate(next30Days.getDate() + 30);
    const location = useLocation();
    const [connectCredit, setConnectCredit] = useState(0);
    const [profiledetail, setprofiledetail] = useState(null);
    const getResumeData = async () => {
        const { data } = await axios.get("/api/user/getResume", {
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token"),
            },
        });
        setprofiledetail(data.resume);
    };

    const getConnectCreditInfo = async () => {
        try {
            const response = await axios.get(`/api/employer/getConnectCreditInfomrationofEmployer/${user?._id}`);
            setConnectCredit(response.data.connectCredit);
        } catch (error) {
            console.error("Error fetching connectCredit:", error);
        }
    };
    useEffect(() => {
        if (user && user.role === "User") {
            getConnectCreditInfo();
        }
    });
    useEffect(() => {
        getResumeData();
    }, []);

    const handleApplyNow = () => {
        navigate("/");
        const { phone, city, resume } = user;
        let url = "";
        if (
            !phone ||
            !city ||
            !resume ||
            !profiledetail ||
            profiledetail.Education.length === 0 ||
            (profiledetail.Job.length === 0 && profiledetail.Internship.length === 0) ||
            // profiledetail.Training.length === 0 ||
            // profiledetail.Academics.length === 0 ||
            profiledetail.Skills.length === 0 ||
            profiledetail.Portfolio.length === 0 ||
            profiledetail.DocDetails.length === 0
        ) {
            console.log("user profile");
            return navigate("/profile");
        } else if ((phone || city) && !resume) {
            return navigate("/profile");
        } else {
            // navigate("/coverLetterPage", { state: state2 });
            const jobId = `${state2.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${state2.opportunityType}-at-${state2.employer.organisationName.replace(/ /g, "-")}_${state2._id}`.toLowerCase();
            const encodedJobId = encodeURIComponent(jobId);
            // navigate(`/coverletterpage/${encodedJobId}`, { state: state2 });
            if (state2.opportunityType === "Hourly" && connectCredit < 8) {
                console.log(connectCredit, "connects");
                setShowModal(true);
            } else if (state2.opportunityType === "Fixed Cost" && connectCredit < 4) {
                setShowModal(true);
            } else {
                url = `/coverletterpage/${encodedJobId}`;
            }
        }
        navigate(url);
    };

    const handleRemoveJob = async (jobId) => {
        try {
            const { data } = await axios.get(`/api/user/removeJob/${jobId}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });

            if (data.success) {
                logEvent("Button", `Clicked removed job`, user ? user.role : "Guest");

                setFilled((prevFilled) => !prevFilled);
                //     window.location.reload();
                dispatch(loadUser());
                toast.success(state2?.opportunityType === "Hourly" ? "Hourly project removed" : "Fixed cost project removed");
            }
        } catch (error) { }
    };
    const handleSaveJob = async (jobId) => {
        try {
            const { data } = await axios.get(`/api/user/saveJob/${jobId}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            if (data.success) {
                logEvent("Button", `Clicked saved job`, user ? user.role : "Guest");

                setFilled(!filled);
                // window.location.reload();
                dispatch(loadUser());
                toast.success(state2?.opportunityType === "Hourly" ? "Hourly project saved" : "Fixed cost project saved");
            }
        } catch (error) { }
    };

    //console.log(state2, "client activity");
    //console.log(findLargestBid(state2?.usersBid), "bid");
    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
    }

    return (
        <div className="r1">
            <div className="sectionn11 mb-3 border">
                <div className="buttonContainer">

                    <div className=""
                        style={{ width: "124px", borderRadius: "5px", fontSize: "13px", textAlign: "center", fontWeight: "500", padding: "3px", color: "#787878", border: "solid 1px #D1D1D1" }}>
                        <img
                            src={arrowup}
                            alt=""
                            style={{
                                marginRight: "5px",
                                height: "17px",
                                marginTop: "-4px",
                            }}
                        />
                        Actively hiring
                    </div>
                    <div style={{ textAlign: "center" }}>
                        {user ? (
                            user?.role !== "Employer" && (
                                <div style={{ textAlign: "center" }}>
                                    {state2?.userApplied?.includes(user ? user?._id : "") ? (
                                        <>
                                            <p className=" py-3 mx-auto"
                                                style={{
                                                    color: "green",
                                                    fontWeight: "400",
                                                    marginTop: "4px",
                                                    borderRadius: "10px",
                                                    width: "120px",
                                                }}
                                            >
                                                <MdOutlineCheckCircle
                                                    style={{
                                                        marginRight: "3px",
                                                        fontSize: "22px",
                                                        marginTop: "-2px",
                                                    }}
                                                />
                                                Applied
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            {isApply && (
                                                <button
                                                    onClick={() => handleApplyNow({ state2 }, handleAddEvent("Apply now"))}
                                                    // target="_blank"
                                                    className="btn lastbutton"
                                                    style={{
                                                        paddingTop: "7px",
                                                        paddingBottom: "7px",
                                                    }}
                                                >
                                                    Apply Now
                                                </button>
                                            )}
                                        </>
                                    )}
                                </div>
                            )
                        ) : (
                            <>
                                <div style={{ textAlign: "center" }}>
                                    <button
                                        onClick={() =>
                                            navigate("/login", {
                                                state: { from: location.pathname },
                                            })
                                        }
                                        className="btn lastbutton"
                                        style={{ paddingTop: "7px", paddingBottom: "7px" }}
                                    >
                                        Apply Now
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {/* ---------------------------------------------------------------------- */}

                <div className="row">
                    {/* <div className="col-9"> */}
                    <div className="responsive-col">
                        <h3 style={{ fontSize: "17px" }} className="title pt-3">
                            {state2?.profile}
                        </h3>
                    </div>
                </div>
                <div className="content mb-5" style={{}}>
                    <div
                        className="py-4 "
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "20px 70px",
                        }}
                    >
                        <div className="statusBoxes">
                            <p>
                                <span>
                                    <img src={circle} alt="" style={{ width: "14px" }} />
                                </span>
                                <span className="mx-2 heeading">START DATE</span>
                            </p>
                            <h3 className="head">{state2.startDate === "Immediately (within next 30 days)" ? <>Immediately</> : <>{state2?.startDate}</>}</h3>
                        </div>

                        <div className="statusBoxes">
                            <p>
                                <span>
                                    <img src={calendar} alt="" style={{ width: "14px" }} />
                                </span>
                                <span className="mx-2 heeading">DURATION</span>
                            </p>
                            <h3 className="head">
                                {state2?.duration} {state2?.durationType || "months"}
                            </h3>
                        </div>

                        {state2?.opportunityType === "Hourly" ? (
                            <>
                                <div className="statusBoxes">
                                    <p>
                                        <span>
                                            <img src={money} alt="" style={{ width: "14px" }} />
                                        </span>
                                        <span className="mx-2 heeading">BUDGET</span>
                                    </p>
                                    {state2?.salary === 0 || null ? (
                                        <>
                                            <h3 className="head">Unpaid</h3>
                                        </>
                                    ) : (
                                        <>
                                            <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                ${state2?.salary}
                                                {state2?.salaryMax ? <> - ${state2?.salaryMax} per hour</> : <></>}
                                            </h3>
                                        </>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="statusBoxes">
                                    <p>
                                        <span>
                                            <img src={money} alt="" style={{ width: "14px" }} />
                                        </span>
                                        <span className="mx-2 heeading">BUDGET</span>
                                    </p>
                                    <>
                                        {state2?.salaryProfile === "Unpaid" ? (
                                            <>
                                                <h3 className="head">Unpaid</h3>
                                            </>
                                        ) : (
                                            <>
                                                {state2?.salaryProfile === "Performance based" ? (
                                                    <>
                                                        <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                            ${state2?.salary} Fixed Cost + ${state2?.incentive} per {state2?.incentiveType}
                                                        </h3>
                                                    </>
                                                ) : (
                                                    <>
                                                        {state2?.salaryProfile === "Negotiable" ? (
                                                            <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                                ${state2?.salary} - ${state2?.salaryMax} Fixed Cost
                                                            </h3>
                                                        ) : (
                                                            <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                                ${state2?.salary} Fixed Cost
                                                            </h3>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                </div>
                            </>
                        )}

                        <div className="statusBoxes">
                            <p>
                                <span>
                                    <img src={game} alt="" style={{ width: "14px" }} />
                                </span>
                                <span className="mx-2 heeading">Apply by</span>
                            </p>
                            <h3 className="head">{state2?.deadline.slice(8, 10) + " " + MONTH_DETAILS[parseInt(state2?.deadline.slice(5, 7), 10)] + "' " + state2?.deadline.slice(2, 4)}</h3>
                        </div>
                    </div>
                    <div className="row py-2 px-1">
                        <span>
                            <span
                                className=" post1"
                                style={{
                                    backgroundColor: determineBgColor(state2.datePosted),
                                    color: determineColor(state2.datePosted),
                                }}
                            >
                                <RxCounterClockwiseClock
                                    style={{
                                        fontSize: "14px",
                                        marginTop: "-2px",
                                    }}
                                />{" "}
                                Posted {timeAgo(state2.datePosted)}
                            </span>
                            <span className="post1" style={{ marginLeft: "10px" }}>
                                {state2?.opportunityType}
                            </span>
                            {state2?.isPartTimeAllowed === "Yes" ? (
                                <span className="post1" style={{ marginLeft: "10px" }}>
                                    Part time
                                </span>
                            ) : (
                                <></>
                            )}
                        </span>
                        <div className="row">
                            <div className="col">
                                <p className="mt-5" style={{ width: "max-content" }}>
                                    <FiUsers
                                        style={{
                                            fontSize: "22px",
                                            color: "#9b9b9b",
                                            marginTop: "-3px",
                                        }}
                                    />
                                    <span className="head" style={{ fontSize: "15px" }}>
                                        {state2?.userApplied && state2?.userApplied?.length > 0 ? <>{state2?.userApplied?.length}</> : <>0</>} applicants
                                    </span>
                                </p>
                            </div>
                            <div className="col">
                                <div className="mt-5 text-end">
                                    {userType && userType === "User" ? (
                                        <>
                                            <FaBookmark
                                                style={{
                                                    position: "absolute",
                                                    top: "20px",
                                                    right: "10px",
                                                    fontSize: "20px",
                                                    color: user && user?.saveJob?.includes(state2._id) ? "#0991DA" : "white",
                                                    cursor: "pointer",
                                                    marginTop: "10px",
                                                }}
                                                onClick={() => {
                                                    if (user && user?.saveJob?.includes(state2._id)) {
                                                        handleRemoveJob(state2?._id);
                                                    } else {
                                                        handleSaveJob(state2?._id);
                                                    }
                                                }}
                                            />

                                            <FaRegBookmark
                                                style={{
                                                    position: "absolute",
                                                    top: "20px",
                                                    right: "10px",
                                                    fontSize: "20px",
                                                    color: "#0991DA",
                                                    cursor: "pointer",
                                                    marginTop: "10px",
                                                }}
                                                onClick={() => {
                                                    if (user && user?.saveJob?.includes(state2?._id)) {
                                                        handleRemoveJob(state2?._id);
                                                    } else {
                                                        handleSaveJob(state2?._id);
                                                    }
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr
                    className=""
                    style={{
                        height: "0.5px",
                        color: "#C4C4C4",
                        marginTop: "-20px",
                    }}
                ></hr>
                <h3 className="title pt-2" style={{ fontSize: "17px" }}>
                    About the project
                    {/* {state2 && state2.opportunityType == "Job"
                    ? "job"
                    : "internship"} */}
                </h3>
                {/* <span className="wfm123 mt-4  pt-3">Key responsibilities:</span>  */}
                <p
                    className="wfm123"
                    style={{
                        marginTop: "20px",
                        whiteSpace: "pre-wrap",
                        lineHeight: "25px",
                    }}
                >
                    {state2?.jobDescription}
                </p>
                <hr
                    className=""
                    style={{
                        height: "0.8px",
                        color: "#C4C4C4",
                        marginTop: "25px",
                    }}
                />
                <h3 className="title pt-2" style={{ fontSize: "17px", marginTop: "25px" }}>
                    Additional Candidate Preference
                </h3>
                <p
                    className="wfm123"
                    style={{
                        marginTop: "20px",
                        whiteSpace: "pre-wrap",
                        lineHeight: "25px",
                    }}
                >
                    {state2?.preference}
                </p>

                <hr
                    className=""
                    style={{
                        height: "0.8px",
                        color: "#C4C4C4",
                        marginTop: "30px",
                    }}
                />

                <h3 className="title pt-2 mt-5" style={{ fontSize: "17px" }}>
                    Who can apply
                </h3>
                <p className="wfm123" style={{ marginTop: "20px" }}>
                    Only those candidates can apply who :
                </p>
                <p className="wfm123">
                    1. are available for the {state2?.jobType === "Remote" ? <>work from home</> : <></>} {state2?.opportunityType?.toLowerCase()} {state2?.jobType === "Remote" ? <></> : <>{state2?.city && state2?.city?.length > 0 ? <>in {state2?.city[0]?.toLowerCase()}</> : <></>}</>}
                </p>
                <p className="wfm123">
                    2. can start the{" "}
                    {/* {state2.jobType === 'Remote' ? <>work from home</> : <></>}{' '}
            {state2.opportunityType.toLowerCase()}{' '} */}
                    {state2?.jobType === "Remote" ? <>work from home</> : <></>} {state2?.opportunityType.toLowerCase()}{" "}
                    {state2?.startDate === "Immediately (within next 30 days)" ? (
                        <>
                            {formatDate(currentDate)} and {formatDate(next30Days)}
                        </>
                    ) : state2?.startDate === "Later" ? (
                        <>internship between {state2?.internshipFrom && state2?.internshipTo ? `${formatDate2(state2?.internshipFrom)} and ${formatDate2(state2?.internshipTo)}` : ""}</>
                    ) : null}
                    {state2?.jobType === "Remote" ? <></> : <>{state2?.city && state2?.city.length > 0 ? <> in {state2?.city[0].toLowerCase()}</> : <></>}</>} {/* {state2.startDate.toLowerCase()} */}
                </p>
                <p className="wfm123">3. have relevant skills and interests</p>
                {state2?.opportunityType === "Fixed Cost" && <p className="wfm123">4. are available for duration of {state2?.duration} months</p>}
                <p className="wfm123">* Women wanting to start/restart their career can also apply.</p>

                <hr
                    className=""
                    style={{
                        height: "0.8px",
                        color: "#C4C4C4",
                        marginTop: "35px",
                    }}
                />

                <h3 className="title" style={{ fontSize: "17px", marginTop: "35px" }}>
                    Project Category: <span style={{ fontSize: "14px", fontWeight: "normal" }}>{state2?.projectCategory}</span>
                </h3>
                <hr
                    className=""
                    style={{
                        height: "0.8px",
                        color: "#C4C4C4",
                        marginTop: "35px",
                    }}
                />

                <h3 className="title" style={{ fontSize: "17px", marginTop: "35px" }}>
                    Project Specialization: <span style={{ fontSize: "14px", fontWeight: "normal" }}>{state2?.projectSpecialization}</span>
                </h3>
                <hr
                    className=""
                    style={{
                        height: "0.8px",
                        color: "#C4C4C4",
                        marginTop: "35px",
                    }}
                />

                <div className="mt-5">
                    <h3 className="title pt-2" style={{ fontSize: "17px" }}>
                        Skill(s) required
                    </h3>
                    {state2?.skill?.map((data, index) => (
                        <p key={index} className="post1234 mr-4 mt-3">
                            {data}
                        </p>
                    ))}
                </div>
                <hr
                    className=""
                    style={{
                        height: "0.8px",
                        color: "#C4C4C4",
                        marginTop: "16px",
                    }}
                />

                {/* {state2.perks && state2.perks.length > 0 && ( */}
                <div className="mt-5 ">
                    <h3 className="title pt-2" style={{ fontSize: "17px" }}>
                        Perks
                    </h3>
                    {state2?.perks?.map((data, idx) => (
                        <p key={idx} className=" post1234 mr-4 mt-3">
                            {data}
                        </p>
                    ))}
                </div>
                {/* )} */}

                <hr
                    className=""
                    style={{
                        height: "0.8px",
                        color: "#C4C4C4",
                        marginTop: "25px",
                    }}
                />

                <div className="mt-5">
                    <h3 className="title pt-2" style={{ fontSize: "17px" }}>
                        Number of freelancers needed
                    </h3>
                    <p className="wfm123 mt-4">{state2?.numberOfOpeining}</p>
                </div>

                <hr
                    className=""
                    style={{
                        height: "0.8px",
                        color: "#C4C4C4",
                        marginTop: "25px",
                    }}
                />

                <div>
                    <h3 className="title" style={{ fontSize: "17px", marginTop: "35px" }}>
                        Activity on this project
                    </h3>
                    <div style={{ marginTop: "20px" }}>
                        <span>
                            Proposals: <span style={{ fontSize: "14px", fontWeight: "normal" }}>{getRangeApplicants(Number(state2?.userApplied?.length))}</span>
                        </span>
                        <br />
                        <span>
                            Last viewed by client:{" "}
                            {activityData?.status && (
                                <span
                                    className=" post1"
                                    style={{
                                        backgroundColor: determineBgColor(activityData?.activitydata?.updatedAt),
                                        color: determineColor(activityData?.activitydata?.updatedAt),
                                    }}
                                >
                                    <RxCounterClockwiseClock
                                        style={{
                                            fontSize: "14px",
                                            marginTop: "-2px",
                                        }}
                                    />{" "}
                                    Active {timeAgo(activityData?.activitydata?.updatedAt)}
                                </span>
                            )}
                        </span>

                        <br />
                        <span>
                            Interviewing: <span style={{ fontSize: "14px", fontWeight: "normal" }}>{appliedData !== null ? appliedData?.ApplicationCounts?.ShortlistedCount : 0}</span>
                        </span>
                        <br />
                        <span>
                            Hired: <span style={{ fontSize: "14px", fontWeight: "normal" }}>{appliedData !== null ? appliedData?.ApplicationCounts?.HiredCount : 0}</span>
                        </span>
                    </div>
                </div>
                <hr
                    className=""
                    style={{
                        height: "0.8px",
                        color: "#C4C4C4",
                        marginTop: "35px",
                    }}
                />

                <div>
                    <h3 className="title pt-2" style={{ fontSize: "14px" }}>
                        Bid range: High ${findLargestBid(state2?.usersBid)}.00 | Avg ${state2?.usersBid?.length === 0 ? "0.00" : calculateAverage(state2?.usersBid)} | Low ${findSmallestBid(state2?.usersBid)}.00
                    </h3>
                </div>
            </div>
            <div className="content mt-5 mb-3">
                {user ? (
                    user?.role !== "Employer" && (
                        <div style={{ textAlign: "center" }}>
                            {state2?.userApplied?.includes(user ? user?._id : "") ? (
                                <>
                                    <p
                                        className=" py-3 mx-auto"
                                        style={{
                                            color: "green",
                                            fontWeight: "400",
                                            marginTop: "4px",
                                            borderRadius: "10px",
                                            width: "120px",
                                        }}
                                    >
                                        <MdOutlineCheckCircle
                                            style={{
                                                marginRight: "3px",
                                                fontSize: "22px",
                                                marginTop: "-2px",
                                            }}
                                        />
                                        Applied
                                    </p>
                                </>
                            ) : (
                                <>
                                    {isApply && (
                                        <button
                                            onClick={() => {handleApplyNow({ state2 }), handleAddEvent("Apply now")}}
                                            // target="_blank"
                                            className="btn lastbutton"
                                            style={{
                                                paddingTop: "7px",
                                                paddingBottom: "7px",
                                            }}
                                        >
                                            Apply Now
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    )
                ) : (
                    <>
                        <div style={{ textAlign: "center" }}>
                            <button
                                onClick={() => {

                                    navigate("/login", {
                                        state: { from: location.pathname },
                                    }),
                                        handleAddEvent("Apply now")
                                }
                                }
                                className="btn lastbutton"
                                style={{ paddingTop: "7px", paddingBottom: "7px" }}
                            >
                                Apply Now
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ProjectDetailsCard;
