import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../Layout/Spinner";
import { location, useLocation } from "react-router-dom";
import { logEvent } from "../../utils/Analytics";
const ChangeEmail = () => {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const location = useLocation();

    const [newEmail, setNewEmail] = useState("");
    const [otp, setOTP] = useState("");
    const [loading, setLoading] = useState(false);
    const [emailChangeInitiated, setEmailChangeInitiated] = useState(false);
    const [hover, setHover] = useState(false);

    const getOtp = async () => {
        try {
            const res = await axios.post("/api/user/getotp", { email: newEmail });
            const logData = res.data;
            return logData.OTP;
        } catch (error) {
            console.error("Error fetching OTP:", error);
            throw error;
        }
    };

    const handleEmailChange = async () => {
        logEvent("Button", `Clicked email change button`, user ? user.role : "Guest");

        try {
            if (!newEmail) {
                toast.error("Please enter a new email address.");
                return;
            }

            setLoading(true);
            const response = await axios.post("/api/user/changeemailrequest", {
                oldEmail: user.email,
                newEmail: newEmail,
            });

            if (response.data.success) {
                setEmailChangeInitiated(true);
                logEvent("Button", `User changed email `, user ? user.role : "Guest");
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error initiating email change:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleOTPVerification = async () => {
        try {
            if (otp === "") {
                toast.error("Please enter the OTP!!");
                return;
            }

            const serverOtp = await getOtp();

            if (otp !== serverOtp) {
                toast.error("Invalid OTP.");
                return;
            }

            setLoading(true);

            const response = await axios.post("/api/user/changeemailverifyotp", {
                oldEmail: user.email,
                newEmail: newEmail,
            });

            if (response.data.success) {
                toast.success(response.data.message);
                window.location.reload();
                /*  dispatch({
		  type: 'USER_LOGOUT',
		});*/
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error changing email:", error);
            toast.error("Failed to change email. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === "Enter") {
                const myButton = document.querySelector(".changeEmail");
                if (myButton) {
                    myButton.click();
                } else {
                    console.error("Button not found");
                }
            }
        };
        // Attach the event listener to the document
        document.addEventListener("keydown", handleKeyPress);
        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, []);
    const handleResendOTP = async () => {
        try {
            setLoading(true);
            const response = await axios.post("/api/user/changeemailrequest", {
                oldEmail: user.email,
                newEmail: newEmail,
            });
            if (response.data.success) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error initiating email change:", error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div
                style={{
                    backgroundColor: "rgba(224, 220, 243, 0.212)",
                }}
            >
                {user && user.role === "User" ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            paddingTop: "40px",
                            paddingBottom: "30px",
                        }}
                    >
                        <p
                            style={{
                                fontWeight: "600",
                                fontSize: "20px",
                                textAlign: "center",
                            }}
                        >
                            Change Email Address
                        </p>
                        <p
                            style={{
                                color: "#666",
                                fontWeight: "400",
                                fontSize: "14px",
                                width: "27%",
                                marginTop: "20px",
                                marginBottom: "20px",
                                display: "block",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px",
                                minWidth: "300px",
                            }}
                        >
                            Note: Please note that all the data associated with your account (<span style={{ fontWeight: "600" }}>{user.email}</span>) will be linked to your new email address after this change. Also, ensure that you have access to both the email accounts for making the change.
                        </p>

                        {/* Display email input field if email change is not initiated */}
                        {!emailChangeInitiated && (
                            <div
                                style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid #DDD",
                                    padding: "20px",
                                    borderRadius: "6px",
                                    width: "27%",
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    minWidth: "300px",
									backgroundColor:"white",
									boxShadow:"0px 0px 15px 0px #00000025",
                                }}
                            >
                                <label htmlFor="new_email">New email address</label>
                                <input
                                    type="email"
                                    name="new_email"
                                    id="new_email"
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    style={{
                                        border: `1px solid ${hover ? "#00A5EC" : "#DDD"}`,
                                        boxShadow: "none",
                                        padding: "11px",
                                        color: "#484848",
                                        borderRadius: "3px",
                                        fontSize: "14px",
                                        height: "40px",
                                        lineHeight: "1.14285714",
                                        textDecoration: "none",
                                        outline: "none",
                                    }}
                                    onMouseEnter={() => setHover(true)}
                                    onMouseLeave={() => setHover(false)}
                                />

                                <button
                                    onClick={handleEmailChange}
                                    className="changeEmail"
                                    style={{
                                        background: "#00A5EC",
                                        borderRadius: "3px",
                                        border: "1px solid #00A5EC",
                                        padding: "12px 16px",
                                        marginTop: "20px",
                                        color: "#FFF",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    }}
                                >
                                    {loading ? <Spinner /> : <span>Change Email</span>}
                                </button>
                            </div>
                        )}

                        {/* Display OTP input field if email change is initiated */}
                        {emailChangeInitiated && (
                            <div
                                style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid #DDD",
                                    padding: "20px",
                                    borderRadius: "6px",
                                    width: "27%",
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                }}
                            >
                                <label htmlFor="otp">Enter OTP</label>
                                <input
                                    type="text"
                                    name="otp"
                                    id="otp"
                                    placeholder="Enter OTP"
                                    value={otp}
                                    onChange={(e) => setOTP(e.target.value)}
                                    style={{
                                        border: `1px solid ${hover ? "#00A5EC" : "#DDD"}`,
                                        boxShadow: "none",
                                        padding: "11px",
                                        color: "#484848",
                                        borderRadius: "3px",
                                        fontSize: "14px",
                                        height: "40px",
                                        lineHeight: "1.14285714",
                                        textDecoration: "none",
                                        outline: "none",
                                    }}
                                    onMouseEnter={() => setHover(true)}
                                    onMouseLeave={() => setHover(false)}
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            handleOTPVerification();
                                        }
                                    }}
                                />
                                <p onClick={handleResendOTP} className="resentOTP" style={{ textAlign: "end", fontSize: "14px", marginTop: "8px", cursor: "pointer" }}>
                                    {loading ? "Loading.." : "Resend OTP"}
                                </p>
                                <button
                                    onClick={handleOTPVerification}
                                    style={{
                                        background: "#00A5EC",
                                        borderRadius: "3px",
                                        border: "1px solid #00A5EC",
                                        padding: "12px 16px",
                                        marginTop: "10px",
                                        color: "#FFF",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    }}
                                >
                                    {loading ? <Spinner /> : <span>Verify OTP</span>}
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
};

export default ChangeEmail;
