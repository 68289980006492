import React, { useEffect } from "react";
import "./css/AnyHire.scss";
import { Link } from "react-router-dom";
import { anyHire_1, anyHire_2, anyHire_3, anyHire_4, anyHire_5 } from "../../assets";
import { useSelector } from "react-redux";
import { logEvent } from "../../utils/Analytics";
const AnyHire = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    const {user} = useSelector((state) => state.user)

    const handleAddEvent = (str) => {
            logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
        }
    return (
        <div className="anyhire-container">
            {/* Hero Section */}
            <div className="anyhire-hero-section">
                <div className="hero-content">
                    <div className="">
                        <h1>Hiring remotely? We've got you covered</h1>
                        <p>Any Hire simplifies setting up and collaborating with professionals you've sourced outside of Workcroft, enabling you to hire anyone, anywhere, confidently</p>
                        <Link to={!user ? "/employeeSignup" : (user?.role === "User" ? "/any-hire" : "/freelancer-details")} onClick={() => handleAddEvent("Get started for free")}>
                            <button className="primary-btn">Get started for free</button>
                        </Link>
                    </div>
                </div>
                <div className="hero-image">
                    <img src={anyHire_1} alt="Person working on laptop" />
                </div>
            </div>

            <section className="globalTalent">
                <div className="avatar-stack">
                    <img src={anyHire_2} alt="Team member 1" className="avatar" />
                    <img src={anyHire_3} alt="Team member 2" className="avatar" />
                    <img src={anyHire_4} alt="Team member 3" className="avatar" />
                </div>

                <div className="globalTalent__content">
                    <div className="globalTalent__left">
                        <h1>
                            We understand,
                            hiring global talent
                            can be challenging
                        </h1>
                        <p className="globalTalent__subtitle">But it doesn't need to be</p>
                    </div>

                    <div className="globalTalent__right">
                        <p className="globalTalent__description">We've been helping businesses hire globally since 2024, and built the world's largest work marketplace in the process.</p>
                        <p className="globalTalent__description" style={{color:'#3D3D3D'}}>Now, we're leveraging our extensive knowledge to assist you in hiring the best talent, regardless of their location or how you discover them. This allows you to concentrate on building a thriving business, while we manage all operational complexities.</p>
                    </div>
                </div>
            </section>

            <div className="growBusiness">
                <h2>Grow your business with Any Hire</h2>

                <div className="growBusiness__right">
                    <p className="growBusiness__text">Join the many other companies leveraging Any Hire to scale their teams.</p>
                    <Link to={!user ? "/employeeSignup" : (user?.role === "User" ? "/any-hire" : "/freelancer-details")} onClick={() => handleAddEvent("Get started for free")}>
                            <button className="primary-btn">Get started for free</button>
                        </Link>
                </div>
            </div>
            <hr />

            <section className="grow">


                <div className="grow__content">
                    <div className="grow__image">
                        <img src={anyHire_5} alt="Business meeting" />
                    </div>

                    <div className="grow__info">
                        <div className="benefits">
                            <div className="benefits__content">
                                <h3>Make the world smaller</h3>
                                <p>Recruit remote talent from nearly every country worldwide (190 countries, to be exact). This allows you to access top-tier professionals without restricting your search to those in close proximity.</p>
                            </div>

                            <div className="benefits__list">
                                <div className="benefit">Effortlessly adhere to local laws</div>
                                <div className="benefit">Do it all yourself. Fast.</div>
                                <div className="benefit">Handle payroll and benefits</div>
                                <div className="benefit">Hiring decisions with support</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="process">
                <h2>Here's how Any Hire operates</h2>

                <div className="process__steps">
                    <div className="process__card">
                        <h3>Provide us with the details</h3>
                        <p>You select the professional you'd like to hire and provide details about the work you want them to do.</p>
                    </div>

                    <div className="process__card">
                        <h3>Get a contract recommendation</h3>
                        <p>We'll suggest a classification decision (either as payrolled employee or contractor) and assist you in creating a compliant contract to send.</p>
                    </div>

                    <div className="process__card">
                        <h3>Get to work</h3>
                        <p>Once they've accepted the contract, you're all set. You can onboard more people, support their work, pay them, and manage key documents—all from a single platform.</p>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default AnyHire;
