import React, { useEffect, useState } from "react";
import "./css/Login.scss";
import Spinner from "../Layout/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, loginEmployer } from "../../actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { envelope, eyeClosed, eyeOpen } from "../../assets";
import { logEvent } from "../../utils/Analytics";
function Login() {
    const [users, setUser] = useState({
        email: "",
        password: "",
    });
    const [hideShowPassword, sethideShowPassword] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const from = location.state?.from || "/";
    
    const { isAuthenticated, loading, user } = useSelector((state) => state.user);

    const handleUserChange = (e) => {
        setUser({
            ...users,
            [e.target.name]: e.target.value,
        });
    };

    const login = async (event) => {
        event.preventDefault();
        if(!users.email){
            toast.error("Email id required");
            return;
        }
        if(!users.password){
            toast.error("Password is required");
            return;
        }
        try {
            const [employerAttempt, freelancerAttempt] = await Promise.allSettled([
                dispatch(loginEmployer(users, "Employer")), 
                dispatch(loginUser(users, "User"))
            ]);
    
            const intendedPath = localStorage.getItem("intendedPath") || "";
    
            if (employerAttempt.status === "fulfilled" && employerAttempt.value?.success) {
                toast.success("Logged in successfully");
                logEvent("Button", "Clicked login button", user ? user.role : "Guest");
                localStorage.setItem("userType", "Employer");
                localStorage.setItem("userLoggedIn", true);
                
                localStorage.removeItem("intendedPath");
                
                if(intendedPath.includes("employerappreceived")) {
                    const urlParams = new URLSearchParams(intendedPath.split('?')[1]);
                    const data = urlParams.get('data');
                    if (data) {
                        navigate(`/employerappreceived/All?data=${data}`);
                    } else {
                        navigate('/employerApplication/Hourly');
                    }
                } else {
                    navigate(intendedPath || "/employerApplication/Hourly");
                }
                return;
            }
            
            // Handle freelancer login success
            if (freelancerAttempt.status === "fulfilled" && freelancerAttempt.value?.success) {
                toast.success("Logged in successfully");
                logEvent("Button", "Clicked login button", user ? user.role : "Guest");

                localStorage.setItem("userType", "User");
                localStorage.setItem("userLoggedIn", true);
                
                localStorage.removeItem("intendedPath");
                
                // Redirect freelancers away from employer pages
                if(intendedPath.includes("employerappreceived") || intendedPath.includes("employer")) {
                    navigate('/');
                } else {
                    navigate(intendedPath || from);
                }
                return;
            }
    
            // Error handling
            const employerError = employerAttempt.status === "rejected" 
                ? employerAttempt.reason?.message?.toLowerCase() 
                : employerAttempt.value?.message?.toLowerCase();
    
            const freelancerError = freelancerAttempt.status === "rejected" 
                ? freelancerAttempt.reason?.message?.toLowerCase() 
                : freelancerAttempt.value?.message?.toLowerCase();
    
            let errorMessage;
            if (employerError?.includes("user not found") && freelancerError?.includes("user not found")) {
                errorMessage = "User not found";
            } else if (employerError?.includes("invalid credentials") || freelancerError?.includes("invalid credentials")) {
                errorMessage = "Invalid password";
            } else if (employerError?.includes("network") || freelancerError?.includes("network")) {
                errorMessage = "Network error occurred. Please try again.";
            } else if (employerError?.includes("active") || freelancerError?.includes("active")) {
                errorMessage = "User not active";
            } else {
                errorMessage = "Login failed. Please try again.";
            }
    
            toast.error(errorMessage);
        } catch (error) {
            console.error("Login process error:", error);
            toast.error("An unexpected error occurred. Please try again.");
        } finally {
            setUser((prev) => ({ ...prev, password: "" }));
        }
    };
    useEffect(() => {
        if (user && user._id) {
            console.log(user);
            const basicDetails = {
                name: user.name,
                email: user.email,
            };
            localStorage.setItem("basicDetails", JSON.stringify(basicDetails)); 
        }
    }, [user]);
    
    

    // Modified useEffect to handle redirection after authentication
    useEffect(() => {
        if (localStorage.getItem("token")) {
            if (user?.role === "Employer") {
                navigate("/employerApplication/Hourly");
            } else if (user?.role === "User" && from) {
                navigate(from);
            }
        }
    }, [isAuthenticated, user, navigate, from]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
       const handleAddEvent = () => {
                logEvent("Button", "Clicked signup button", user ? user.role : "Guest");
            };
    // Rest of your component code...

    return (
        <>
            <div className="login-component" >
                <form className="form-box">
                    <div className="form">
                        <h1 className="title">Log in to Workcroft</h1>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={users.email}
                            onChange={handleUserChange}
                            name="email"
                            id="email"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <img src={envelope} className="icons" alt="email" />
                                    </InputAdornment>
                                ),
                                style: { outline: "none" },
                            }}
                        />

                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type={hideShowPassword ? "text" : "password"}
                            label="Password"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={users.password}
                            onChange={handleUserChange}
                            name="password"
                            id="password"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{hideShowPassword ? <img src={eyeOpen} className="icons" style={{ cursor: "pointer" }} alt="password" onClick={() => sethideShowPassword(!hideShowPassword)} /> : <img src={eyeClosed} className="icons" alt="password" style={{ cursor: "pointer" }} onClick={() => sethideShowPassword(!hideShowPassword)} />}</InputAdornment>,
                            }}
                        />

                        <div style={{ float: "right" }}>
                            <Link to="/recoveryemail">
                                <small style={{ fontSize: "13px", color: "gray" }}>Forgot Password?</small>
                            </Link>
                        </div>

                        <button onClick={login} className="btn" style={{ paddingTop: "16px", paddingBottom: "16px" }}>
                            {loading ? <Spinner /> : <span style={{ fontWeight: "800" }}>Log In</span>}
                        </button>

                        <p className="text-center pt-4" style={{ fontSize: "13px", marginTop: "12px" }}>
                            Don't have an Account?{" "}
                            <Link to="/signup" onClick={handleAddEvent} state={{ from: location.state?.from || location.pathname }} style={{ fontSize: "13px", fontWeight: "600", color: "#1F57C3" }}>
                                Sign up
                            </Link>
                        </p>
                    </div>
                </form>
            </div>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default Login;

// const handleSuccessfulLogin = () => {
//     const intendedPath = localStorage.getItem('intendedPath');
//     const jobApplyData = localStorage.getItem('jobApplyData');

//     console.log("from login page ", intendedPath, jobApplyData);

//     if (intendedPath) {
//         try {
//             if (jobApplyData) {
//                 const parsedJobData = JSON.parse(jobApplyData);

//                 // Always open job application in new tab if it's a coverletter page
//                 if (intendedPath.includes('coverletterpage')) {
//                     window.open(intendedPath, "_blank");
//                     navigate('/', { replace: true });
//                 } else {
//                     // For other paths (like profile completion), redirect in same window
//                     navigate(intendedPath, { replace: true });
//                 }
//             } else {
//                 // If no job data but we have an intended path, just navigate
//                 navigate(intendedPath, { replace: true });
//             }
//         } catch (error) {
//             console.error('Error processing job apply data:', error);
//             navigate('/', { replace: true });
//         }
//     } else {
//         // Default navigation if no intended path
//         navigate('/', { replace: true });
//     }

//     // Clear stored data
//     localStorage.removeItem('intendedPath');
//     localStorage.removeItem('jobApplyData');
// }
