import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AdminInputControl.scss"
const AdminInputControl = () => {
    const [creditForEmployers, setCreditForEmployers] = useState("");
    const [connectsNeededPerJob, setConnectsNeededPerJob] = useState("");
    const [connectsNeededPerInternship, setConnectsNeededPerInternship] = useState("");
    const [connectperjobseekercontact, setConnectperjobseekercontact] = useState("");
    const [dollaramount, setDollarAmount] = useState("");

    useEffect(() => {
        fetchConnectInfo();
    }, []);
    useEffect(() => {
        fetchdollarInfo();
    }, []);

    const fetchConnectInfo = async () => {
        try {
            const response = await axios.get("/api/admin/getconnectinformationadmin");
            console.log(response);

            const { connectCredit, connectperjob, connectperinternship, connectperjobseekercontact } = response.data;
            setCreditForEmployers(connectCredit);
            setConnectsNeededPerJob(connectperjob);
            setConnectsNeededPerInternship(connectperinternship);
            setConnectperjobseekercontact(connectperjobseekercontact);
        } catch (error) {
            console.error("Error fetching connect information:", error);
        }
    };
    const fetchdollarInfo = async () => {
        try {
            const response = await axios.get("/api/admin/getdollaramountadmin");
            const { dollaramount } = response.data;
            setDollarAmount(dollaramount);
        } catch (error) {
            console.error("Error fetching", error);
        }
    };
    const handleEmployersCreditUpdate = async () => {
        try {
            await axios.post("/api/admin/connectforemployer", {
                creditForEmployers,
            });
            toast.success("Credit Saved");
            console.log("Connect credit saved successfully.");
        } catch (error) {
            console.error("Error saving connect credit:", error);
        }
    };

    const handleJobConnectsUpdate = async () => {
        try {
            await axios.post("/api/admin/jobconnect", {
                connectsNeededPerJob,
            });
            toast.success("Connect per job Saved");
            console.log("Connect per hourly project saved successfully.");
        } catch (error) {
            console.error("Error saving connect per job:", error);
        }
    };

    const handleInternshipConnectsUpdate = async () => {
        try {
            await axios.post("/api/admin/internshipconnect", {
                connectsNeededPerInternship,
            });
            toast.success("Connect per fixed cost project Saved");
            console.log("Connect per internship saved successfully.");
        } catch (error) {
            console.error("Error saving connect per internship:", error);
        }
    };
    const handleJobSeekerContactView = async () => {
        try {
            await axios.post("/api/admin/connectspentviewjobseeker", {
                connectperjobseekercontact,
            });
            toast.success("Connect per freelancer contact View Saved");
            console.log("Connect per Jobseeker Contact View Saved successfully.");
        } catch (error) {
            console.log("Error in upating job seeker contact view connect");
        }
    };

    const handleDollaramountUpdate = async () => {
        try {
            await axios.post("/api/admin/setdollaramountadmin", {
                dollaramount,
            });
            toast.success("Amount Saved");
        } catch (error) {
            console.error("Error saving amount", error);
        }
    };

    return (
        <>
            <div className="wrapper" style={{backgroundColor:"rgba(224, 220, 243, 0.212)"}}>
            <Container className="my-applicationsAdmin" style={{ maxWidth: "550px",backgroundColor:'none' }}>
                <h1>Welcome</h1>

                <div style={{ fontSize: "16px", padding: "0rem", marginBottom: "-31px", marginTop: "-37px", fontWeight: "600" }}>Connects credit / Value of connects</div>
                <div className="border rounded shadow mainWrappper">
                    <div>
                        <Form.Group controlId="creditForEmployers">
                            <Form.Label style={{ marginLeft: "3px" }}>#connects credit to new Freelancer</Form.Label>
                            <div className="AdminSearchBox" style={{ marginTop: "10px", textAlign: "justify" }}>
                                <input className="AdminSearchInput" type="number" placeholder="Enter credit for employers" value={creditForEmployers} onChange={(e) => setCreditForEmployers(e.target.value)} />
                                <button className="AdminSearchButton" onClick={handleEmployersCreditUpdate}>
                                    Update
                                </button>
                            </div>
                        </Form.Group>
                    </div>

                    <div className="mt-4">
                        <Form.Group controlId="connectsNeededPerJob">
                            <Form.Label style={{ marginLeft: "3px" }}>#Connects needed per Hourly Project apply</Form.Label>
                            <div className="AdminSearchBox" style={{ marginTop: "10px", textAlign: "justify" }}>
                                <input className="AdminSearchInput" type="number" placeholder="Enter connects needed per job post" value={connectsNeededPerJob} onChange={(e) => setConnectsNeededPerJob(e.target.value)} />
                                <button className="AdminSearchButton" onClick={handleJobConnectsUpdate}>
                                    Update
                                </button>
                            </div>
                        </Form.Group>
                    </div>
                    <div className="mt-4">
                        <Form.Group controlId="connectsNeededPerInternship">
                            <Form.Label style={{ marginLeft: "3px" }}>#Connects needed per Fixed Project apply</Form.Label>
                            <div className="AdminSearchBox" style={{ marginTop: "10px", textAlign: "justify" }}>
                                <input className="AdminSearchInput" type="number" placeholder="Enter connects needed per internship post" value={connectsNeededPerInternship} onChange={(e) => setConnectsNeededPerInternship(e.target.value)} />
                                <button className="AdminSearchButton" onClick={handleInternshipConnectsUpdate}>
                                    Update
                                </button>
                            </div>
                        </Form.Group>
                    </div>
                    <div className="mt-4">
                        <Form.Group controlId="connectsNeededPerInternship">
                            <Form.Label style={{ marginLeft: "3px" }}>#To view a freelancer's contact information (email, phone, direct chat) on the "Find Talent" page, you'll need to use 1 Connect.</Form.Label>
                            <div className="AdminSearchBox" style={{ marginTop: "10px", textAlign: "justify" }}>
                                <input className="AdminSearchInput" type="number" placeholder="Enter connects needed per jobseeker contact view" value={connectperjobseekercontact} onChange={(e) => setConnectperjobseekercontact(e.target.value)} />
                                <button className="AdminSearchButton" onClick={handleJobSeekerContactView}>
                                    Update
                                </button>
                            </div>
                        </Form.Group>
                    </div>
                    <div className="mt-4">
                        <Form.Group controlId="dollarChange">
                            <Form.Label style={{ fontWeight: "500", marginLeft: "4px" }}>{`Value of 1 connect ${dollaramount} USD`}</Form.Label>

                            <div className="AdminSearchBox" style={{ marginTop: "10px", textAlign: "justify" }}>
                                <input className="AdminSearchInput" type="number" placeholder="Enter amount in $" value={dollaramount} onChange={(e) => setDollarAmount(e.target.value)} />
                                <button className="AdminSearchButton" onClick={handleDollaramountUpdate}>
                                    Update
                                </button>
                            </div>
                        </Form.Group>
                    </div>
                    {/* <div className='mt-4'>
            <Form.Group controlId="connectsNeededPerInternship">
                <div style={{fontSize:'14px',textAlign:'center' ,fontWeight:'500',marginTop:'10px'}}>
                {`Value of 1 connects ${dollaramount} USD`}
                </div>
            </Form.Group>
            </div> */}
                </div>
            </Container>
            </div>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
};

export default AdminInputControl;
