import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CloseButton from "../Student_New/CloseButton";
import PropagateLoader from "react-spinners/PropagateLoader";
import PreviewModel from "../Modal/PreviewModel";
import Pagination from "../Student_New/Pagination";
import { RxDotFilled, RxPencil1 } from "react-icons/rx";
import MonthCalculator from "../Student_New/MonthCalculator";
import SeeMoreLess from "../Student_New/SeeMoreLess";
import { RiDeleteBinLine } from "react-icons/ri";
import { MONTH_DETAILS } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../actions/userActions";
import SimpleDialogNew from "./Employertemplates/ChatDialogNew";
import ChatDialogtalent from "./Employertemplates/ChatDialogtalent";
import { logEvent } from "../../utils/Analytics";

const PreviewCandidate = () => {
    const [resumeData, setResumeData] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [hoveredProject, setHoveredProject] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [hoverText, setHoverText] = useState("Copy to clipboard!");
    const [linkCopied, setLinkCopied] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [openPopup, setOpenPopup] = useState(false);
    const param = useParams();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);
    const userType = localStorage.getItem("userType");
    console.log(user);

    useEffect(() => {
        dispatch(loadUser());
    }, []);

    const projectsPerPage = 3;
    const totalPages = Math.ceil(resumeData?.resume?.Portfolio.length / projectsPerPage);
    console.log("total pages", totalPages)
    const displayedProjects = resumeData?.resume?.Portfolio?.slice((activePage - 1) * projectsPerPage, activePage * projectsPerPage);

    const ChatToUser = async (data) => {
        logEvent("Button", `Clicked message button`, user ? user.role : "Guest");
        
        try {
            const response = await axios.post("/api/user/createRoom", {
                userId: resumeData._id,
                employerId: user && user._id,
                jobId: "6613d85f0ef12e507f453a46",
            });
            // if (response.data.success) {
            //   navigate("/message", { state: response.data.data });
            // }
        } catch (error) {
            console.error("Error occurred while sending request:", error);
        }
    };

    const handleProjectClick = async (project) => {
        //console.log(project, "jfkdk");
        setLoading(true);
        setSelectedProject(null);
        await new Promise((resolve) => setTimeout(resolve, 500));
        setSelectedProject(project);
        setLoading(false);
    };

    const calculateTotalExperience = (internships, jobs) => {
        let totalMonths = 0;

        // Function to calculate the difference in months between two dates
        function monthDiff(d1, d2) {
            let months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }

        // Calculate total months for internships if they exist
        if (internships && internships.length > 0) {
            internships.forEach((internship) => {
                const startDate = new Date(internship.startDate);
                const endDate = new Date(internship.endDate);
                totalMonths += monthDiff(startDate, endDate);
            });
        }

        // Calculate total months for jobs if they exist
        if (jobs && jobs.length > 0) {
            jobs.forEach((job) => {
                const startDate = new Date(job.startDate);
                const endDate = new Date(job.endDate);
                totalMonths += monthDiff(startDate, endDate);
            });
        }

        // If no experience exists, return empty string
        if (totalMonths === 0) {
            return "";
        }

        // Calculate years and remaining months
        const years = Math.floor(totalMonths / 12);
        const months = totalMonths % 12;

        // Construct the result string
        let result = "(";
        if (years === 1) {
            result += years + " YEAR ";
        } else if (years > 1) {
            result += years + " YEARS ";
        }

        if (months === 1) {
            result += months + " MONTH";
        } else if (months > 1) {
            result += months + " MONTHS";
        }
        result += ")";

        return result;
    };

    const tooltipStyles = {
        position: "absolute",
        top: "100%",
        left: "80%",
        transform: "translateX(-50%)",
        marginBottom: "8px",
        padding: "5px 10px",
        background: "#555",
        color: "#fff",
        borderRadius: "4px",
        fontSize: "12px",
        whiteSpace: "nowrap",
        zIndex: 10,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Optional: adds shadow to tooltip
    };

    const getYouTubeEmbedUrl = (url) => {
        try {
            const urlObj = new URL(url);
            let videoId;

            if (urlObj.hostname === "youtu.be") {
                videoId = urlObj.pathname.substring(1);
            } else if (urlObj.pathname.includes("/shorts/")) {
                videoId = urlObj.pathname.split("/shorts/")[1];
            } else {
                videoId = urlObj.searchParams.get("v");
            }

            return `https://www.youtube.com/embed/${videoId}`;
        } catch (e) {
            console.error("Invalid URL", e);
            return null;
        }
    };

    useEffect(() => {
        const fetchResumeData = async () => {
            try {
                if (param.CandidateId) {
                    const { data } = await axios.get(`/api/user/getResumebyId/${param.CandidateId}`, {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    });
                    setResumeData(data.user);
                }
            } catch (e) {
                console.error(e);
                toast.error("Something went wrong while fetching the resume");
            }
        };

        fetchResumeData();
    }, [param]);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(resumeData?.resume?.PersonalDetails?.videoIntroduction);
        setLinkCopied(true);
        setHoverText("Copied!");
        setShowTooltip(true);
        setTimeout(() => {
            setHoverText("Copy to clipboard!");
            setLinkCopied(false);
            setShowTooltip(false);
        }, 800);
    };
    const [emailData, setEmailData] = useState(null);

    useEffect(() => {
        const fetchEmailData = async () => {
            try {
                const response = await axios.get(`/api/employer/getUserEmailsFromEmployer/${user?._id}`);

                setEmailData(response?.data?.userEmails);
            } catch (e) {
                console.error(e);
            }
        };

        fetchEmailData();
    }, [resumeData]);

    console.log(emailData);

    const handleChatClick = async () => {
        let id = resumeData?._id;

        // Redirect to profile setup if organization details are missing
        if (user && (!user.organisationName || !user.organisationDescription)) {
            navigate("/myProfile/company");
            return;
        }

        if (!emailData || !emailData.includes(resumeData.email)) {
            try {
                if (!resumeData?.appliedJobs?.some((job) => job.employer === user._id)) {
                    console.log("call");

                    const response = await axios.post("/api/user/deductConnectCreditoffreelancer", { freelancerId: id });

                    if (response.data.message === "Please Buy Connects.") {
                        setShowModal(true);
                        return;
                    }
                }

                await axios.post("/api/employer/saveUserEmailInEmployer", {
                    employerId: user._id,
                    userEmail: resumeData.email,
                });
            } catch (error) {
                console.error("Error in deducting credits or saving email:", error);
            }
        }
        ChatToUser();
        setOpenPopup(!openPopup);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            console.log(window.innerWidth, "windowWidth");
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

     const handleAddEvent = (str) => {
            logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
        }
    return (
        <>
            <div className="modal fade" id="previewmodalresume" tabIndex="-1" aria-labelledby="previewModalresumeLabel" aria-hidden="true">
                <div className="modal-dialog custom-modal-dialog unique-modal-dialog modal-dialog-centered width-settingofmodal">
                    <div
                        className="modal-content px-3 py-3"
                        style={{
                            borderRadius: "15px",
                            // // marginTop: "10px",
                            height: "95vh",
                        }}
                    >
                        <div className="preview-header">
                            <h2 style={{ fontSize: "28px" }}>{selectedProject?.projectTitle}</h2>

                            <CloseButton className="btnclosepreview" />
                        </div>
                        <div
                            className="modal-body"
                            style={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                overflowY: "auto",
                                paddingLeft: "50px",
                                paddingRight: "50px",
                            }}
                        >
                            {loading ? (
                                <div className="" style={{ textAlign: "center", paddingBottom: "220px" }}>
                                    <p
                                        style={{
                                            color: "#4B92C8",
                                            fontWeight: "500",
                                            fontSize: "20px",
                                            marginLeft: "43px",
                                            marginBottom: "5px",
                                            marginTop: "220px",
                                        }}
                                    >
                                        Loading..
                                    </p>
                                    <PropagateLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" style={{ marginLeft: "27px" }} />
                                </div>
                            ) : (
                                selectedProject && (
                                    <div className={`fade-in ${!loading ? "show" : ""}`}>
                                        <div className="mb-3 flex-container-portfolio">
                                            <div
                                                className="leftsectionpreviewport"
                                                style={{
                                                    flex: 1,
                                                    marginRight: "20px",
                                                    position: "sticky",
                                                    alignSelf: "flex-start",
                                                }}
                                            >
                                                {/* <div
                        style={{ marginTop: "15px", marginBottom: "40px" }}
                      >

                      modfhdjhfujdyfydyfu
                        <h2 style={{ fontSize: "28px" }}>
                          {selectedProject.projectTitle}
                        </h2>
                      </div> */}
                                                {selectedProject.yourRole !== "" && (
                                                    <div style={{ marginBottom: "25px" }}>
                                                        <p style={{ fontSize: "16px" }}>
                                                            <b>My role:</b> {selectedProject.yourRole}
                                                        </p>
                                                    </div>
                                                )}
                                                {selectedProject.projectDescription !== "" && (
                                                    <div style={{ marginBottom: "25px" }}>
                                                        <p style={{ fontSize: "16px" }}>
                                                            <b>Project Description:</b>{" "}
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: selectedProject.projectDescription.replace(/\n/g, "<br />"),
                                                                }}
                                                            />
                                                            {/* {selectedProject.projectDescription} */}
                                                        </p>
                                                    </div>
                                                )}
                                                <div style={{ marginBottom: "25px" }}>
                                                    <h4>Skills and deliverables</h4>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                        }}
                                                    >
                                                        {selectedProject.skills.map((skill) => (
                                                            <span key={skill.value} className="post1234forresume mr-4 mt-3">
                                                                {skill.label}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <hr
                                                    //className="dropdown-divider1 mb-4 mt-3"
                                                    style={{
                                                        height: "0.1px",
                                                        color: "rgb(185, 185, 185)",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="mb-3 rightsectionpreviewport"
                                                style={{
                                                    flex: 2,
                                                    minHeight: "240px",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <PreviewModel portfolioDetails={selectedProject} />

                                                <hr
                                                    className="dropdown-divider1 mb-4 mt-3"
                                                    style={{
                                                        height: "0.05px",
                                                        color: "rgb(185, 185, 185)",
                                                    }}
                                                />
                                                <div className="mb-3" style={{ marginTop: "35px" }}>
                                                    {/* <p style={{ fontSize: "18px", marginBottom: '15px' }}>More by <span style={{ color: "#2092c7" }}>{resumeData.PersonalDetails.firstName} {resumeData.PersonalDetails.lastName}</span></p> */}
                                                    <p style={{ fontSize: "18px", marginBottom: "15px" }}>
                                                        More by{" "}
                                                        <a href={"#"} style={{ color: "#2092c7" }}>
                                                            {resumeData?.resume?.PersonalDetails.firstName} {resumeData?.resume?.PersonalDetails.lastName}
                                                        </a>
                                                    </p>
                                                    <div className="row">
                                                        {displayedProjects
                                                            ?.filter((project) => project._id !== selectedProject._id) // Exclude selected project
                                                            .slice(0, 3) // Get next 3 projects
                                                            .map((project) => (
                                                                <div className="col-md-4" key={project._id} style={{ cursor: "pointer" }} onClick={() => handleProjectClick(project)}>
                                                                    <div className="card">
                                                                        <img className="card-img-top" style={{ width: "100%", height: "200px", objectFit: "cover" }} src={project.selectedThumbnail.url} alt={`Project ${project._id}`} />
                                                                        <div className="card-body">
                                                                            <p className="card-text" style={{ color: "#2092c7" }}>
                                                                                {project.projectTitle}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {resumeData ? (
                <div className="pb-5 wholeProfileWrapper paddingWrapper" style={{ backgroundColor: "rgba(224, 220, 243, 0.212)" }} >
                    <div className="InvoiceDownload section31 my-4 " style={{ letterSpacing: "0.3px" }}>
                        <div className="">
                            {/* Personal details wrapper  */}
                            <div>
                                {resumeData !== null && (
                                    <>

                                        <div className="personalDetailsWrapper">
                                            <div className="borderBox  leftWrapper layoutDesign" style={{ flex: "1", marginTop: "10px" }}>
                                                <div style={{ display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                                                    {resumeData?.resume?.PersonalDetails?.photo?.url !== "" && (
                                                        <div className="phototodisplayindesktop">
                                                            <img
                                                                src={resumeData?.resume?.PersonalDetails?.photo?.url}
                                                                alt="Profile"
                                                                style={{
                                                                    boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "cover",
                                                                    borderRadius: "100%",
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    <div className="d-flex" style={{ alignItems: "center", marginTop: "5px" }}>
                                                        <p style={{ fontSize: "25px", fontWeight: "500", color: "#333333", marginBottom: "3px" }}>
                                                            {resumeData?.resume?.PersonalDetails?.firstName} {resumeData?.resume?.PersonalDetails?.lastName}
                                                        </p>
                                                    </div>
                                                    <p style={{ fontSize: "17px", color: "#333333", marginBottom: "0px", textAlign: 'center' }}>{resumeData?.resume?.PersonalDetails?.professionalTitle}</p>

                                                    {resumeData?.resume?.PersonalDetails?.minimumRate && (
                                                        <>
                                                            <p className="personalHeading">Hourly Rate ($)</p>
                                                            <p className="personalContent" style={{ marginTop: "-8px", marginBottom: "3px" }}>
                                                                ${resumeData?.resume?.PersonalDetails?.minimumRate} per hour
                                                            </p>
                                                        </>
                                                    )}
                                                    {(emailData?.includes(resumeData?.email) ||
                                                        resumeData?.appliedJobs?.some(job => job.employer === user?._id)) && (
                                                            <>
                                                                <p className="personalHeading">Contact Number</p>
                                                                <p className="personalContent"
                                                                    style={{ marginTop: "-8px", marginBottom: "3px" }}>
                                                                    {resumeData?.resume?.PersonalDetails?.phone}
                                                                </p>

                                                                <p className="personalHeading">Email ID</p>
                                                                <p className="personalContent"
                                                                    style={{ marginTop: "-8px", marginBottom: "3px", textAlign: 'center' }}>
                                                                    {resumeData?.resume?.PersonalDetails?.email}
                                                                </p>

                                                            </>
                                                        )}
                                                    <p className="personalHeading">Location</p>
                                                    <p className="personalContent"
                                                        style={{ marginTop: "-8px", marginBottom: "3px", textAlign: "center" }}>
                                                        {resumeData?.resume?.PersonalDetails?.address}
                                                    </p>
                                                    <div style={{ display: "flex", justifyContent: "flex-end", }} >

                                                        <div className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                                                            {user?.role === 'Employer' &&
                                                                <button className="shortcut-newappliedjobForPreview" onClick={handleChatClick} style={{ color: "#1177d6" }} > Message </button>
                                                            }
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                            <div className="rightWrapper" style={{ flex: "2", gap: "20px 0px" }}>
                                                {/* {resumeData?.resume?.PersonalDetails?.videoIntroduction && ( */}
                                                <div className="video-introduction rightOne layoutDesign" style={{ marginTop: "10px", height: resumeData?.resume?.PersonalDetails?.videoIntroduction === "" && "auto" }}>

                                                    <div className="d-flex">
                                                        <h2 className="video-introduction__title">Video Introduction</h2>{" "}

                                                        {(userType !== "User" || resumeData?._id !== user?._id) && resumeData?.resume?.PersonalDetails?.videoIntroduction !== "" && (<span
                                                            style={{ marginBottom: "10px", marginLeft: "5px" }}
                                                            className="video-introduction__copy-button"
                                                            onClick={() => {handleCopyToClipboard() ,handleAddEvent("Copy to clipboard video introduction") }}
                                                            onMouseEnter={() => {
                                                                if (!linkCopied) setHoverText("Copy to clipboard!");
                                                                setShowTooltip(true);
                                                            }}
                                                            onMouseLeave={() => setShowTooltip(false)}
                                                        >
                                                            <MdContentCopy />
                                                            {showTooltip && <div className="video-introduction__tooltip">{hoverText}</div>}
                                                        </span>)}
                                                    </div>
                                                    {(userType === "User" && resumeData?._id === user?._id) && (
                                                        <div className="video-introduction__link-container">
                                                            <a href={resumeData?.resume?.PersonalDetails?.videoIntroduction} target="_blank" rel="noopener noreferrer" className="video-introduction__link">
                                                                Your Video Introduction
                                                            </a>
                                                            <span
                                                                className="video-introduction__copy-button"
                                                                onClick={() => {handleCopyToClipboard() ,handleAddEvent("Copy to clipboard video introduction") }}

                                                                onMouseEnter={() => {
                                                                    if (!linkCopied) setHoverText("Copy to clipboard!");
                                                                    setShowTooltip(true);
                                                                }}
                                                                onMouseLeave={() => setShowTooltip(false)}
                                                            >
                                                                <MdContentCopy />
                                                                {showTooltip && <div className="video-introduction__tooltip">{hoverText}</div>}
                                                            </span>
                                                        </div>
                                                    )}
                                                    <div className="video-introduction__wrapper" style={{ paddingTop: resumeData?.resume?.PersonalDetails?.videoIntroduction === "" && "0px" }}>
                                                        <iframe src={getYouTubeEmbedUrl(resumeData?.resume?.PersonalDetails?.videoIntroduction)} title="Video Introduction" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="video-introduction__iframe" />
                                                    </div>
                                                </div>
                                                {/* )} */}

                                                {resumeData?.resume?.PersonalDetails?.profileCategories && (
                                                    <div className="d-flex align-items-start justify-content-start borderBox  flex-column layoutDesign rightTwo " style={{ width: "100%" }}>
                                                        <div className="d-flex">
                                                            {resumeData?.resume?.PersonalDetails?.profileCategories && <p style={{ margin: "0px", fontWeight: "400", fontSize: "18px", color: "#666666" }}> Profile Category </p>}
                                                        </div>

                                                        {resumeData?.resume?.PersonalDetails?.profileCategories.map((category, index) => (
                                                            <div key={category.id}>
                                                                <p style={{ fontSize: "17px", color: "#333333", fontWeight: "400" }}>{category?.profile}</p>
                                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                                    {category?.specializations?.map((spec, specIndex) => (
                                                                        <span key={specIndex} className="post1234forresume mr-4" style={{ marginBottom: "10px" }}>
                                                                            {spec}
                                                                        </span>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        ))}


                                                        {resumeData?.resume?.PersonalDetails?.profileSummary && (
                                                            <div style={{ marginTop: "10px", fontSize: "15px", color: "#666666" }}>
                                                                <div className="d-flex">
                                                                    <p style={{ fontWeight: "400", fontSize: "18px", color: "#666666" }}>Profile Summary</p>
                                                                </div>
                                                                <p style={{ fontSize: "16px", color: "#333333" }}>
                                                                    {(() => {
                                                                        const content = resumeData?.resume?.PersonalDetails?.profileSummary;
                                                                        const lines = content.split("\n");
                                                                        const isMultiLine = lines.length > 5;

                                                                        // Function to truncate text at word boundary
                                                                        const truncateText = (text) => {
                                                                            if (text.length <= 330) return text;
                                                                            let truncated = text.slice(0, 330);
                                                                            const lastSpace = truncated.lastIndexOf(" ");
                                                                            return lastSpace > 0 ? truncated.slice(0, lastSpace) : truncated;
                                                                        };

                                                                        if (isMultiLine) {
                                                                            // Handle multi-line content
                                                                            const visibleLines = isExpanded ? lines : lines.slice(0, 5);
                                                                            const joinedContent = visibleLines.join("\n");
                                                                            const truncatedContent = !isExpanded ? truncateText(joinedContent) : joinedContent;

                                                                            return (
                                                                                <>
                                                                                    <span
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: truncatedContent.replace(/\n/g, "<br />"),
                                                                                        }}
                                                                                    />
                                                                                    {(lines.length > 5 || content.length > 330) && (
                                                                                        <>
                                                                                            {!isExpanded && <span>...</span>}
                                                                                            <span
                                                                                                onClick={() => setIsExpanded(!isExpanded)}
                                                                                                style={{
                                                                                                    color: "#2092c7",
                                                                                                    cursor: "pointer",
                                                                                                    textDecoration: "underline",
                                                                                                    marginLeft: "5px",
                                                                                                }}
                                                                                            >
                                                                                                {isExpanded ? "less" : "more"}
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        } else {
                                                                            // Handle single paragraph content
                                                                            const truncatedContent = !isExpanded ? truncateText(content) : content;

                                                                            return (
                                                                                <>
                                                                                    <span
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: truncatedContent.replace(/\n/g, "<br />"),
                                                                                        }}
                                                                                    />
                                                                                    {content.length > 330 && (
                                                                                        <>
                                                                                            {!isExpanded && <span>...</span>}
                                                                                            <span
                                                                                                onClick={() => setIsExpanded(!isExpanded)}
                                                                                                style={{
                                                                                                    color: "#2092c7",
                                                                                                    cursor: "pointer",
                                                                                                    textDecoration: "underline",
                                                                                                    marginLeft: "5px",
                                                                                                }}
                                                                                            >
                                                                                                {isExpanded ? "less" : "more"}
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        }
                                                                    })()}
                                                                </p>
                                                            </div>
                                                        )}

                                                        {resumeData?.resume?.PersonalDetails?.availability && (
                                                            <div style={{ marginTop: "10px", fontSize: "15px", color: "black" }}>
                                                                <div className="d-flex">
                                                                    <p style={{ fontWeight: "400", fontSize: "18px", color: "#666666" }}>Availability</p>
                                                                </div>
                                                                <p style={{ marginTop: "-6px", fontSize: "16px", color: "black" }}>{resumeData?.resume?.PersonalDetails?.availability}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            {/* Portfolio wrapper  */}

                            <div className="layoutDesign" id="portfolioWrapper">
                                <div className="row">
                                    <div className="col-12">
                                        <p style={{ color: "#333333", fontSize: "22px", letterSpacing: "0.5px", fontWeight: "700", marginTop: "15px" }}>Project Portfolio</p>
                                    </div>

                                    {resumeData?.resume?.Portfolio.length !== 0 && (

                                        <div className={`col-12 mobile-column-to-increase-width`}>
                                            <div className="portfolio-tabs">
                                                {/* display all portfolios */}
                                                <div className="tab-content">
                                                    <div id="published" className="tab-pane active">
                                                        <div className="row">
                                                            {displayedProjects?.map((project) => (
                                                                <div
                                                                    className="col-md-4"
                                                                    key={project?.id}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        positon: "relative",
                                                                    }}
                                                                    onMouseEnter={() => setHoveredProject(project?._id)}
                                                                    onMouseLeave={() => setHoveredProject(null)}
                                                                >
                                                                    <div className="card portcard" data-bs-toggle="modal"
                                                                        data-bs-target="#previewmodalresume" onClick={() => {
                                                                            setSelectedProject(project);
                                                                            handleAddEvent("Preview portfolio")
                                                                        }}>
                                                                        {/* <img className="card-img-top"  src={project.selectedThumbnail.url} alt={`Project ${project._id}`} /> */}
                                                                        <img
                                                                            className={`card-img-top project-image ${hoveredProject === project?._id
                                                                                ? "dim-image"
                                                                                : ""
                                                                                }`}
                                                                            src={project?.selectedThumbnail?.url}
                                                                            alt={`Project ${project._id}`}
                                                                        />
                                                                        <div className="card-body">
                                                                            <p
                                                                                className="card-text project-title"
                                                                                style={{ color: "#2092c7" }}
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#previewmodalresume"
                                                                                onClick={() => {
                                                                                    setSelectedProject(project);
                                                                                    handleAddEvent("Preview portfolio")

                                                                                }}
                                                                            >
                                                                                {windowWidth > 1068 ? (project?.projectTitle.length > 60 ? project?.projectTitle.slice(0, 60) + "..." : project?.projectTitle) : windowWidth < 768 && windowWidth > 468 ? (project?.projectTitle.length > 100 ? project?.projectTitle.slice(0, 100) + "..." : project?.projectTitle) : windowWidth < 468 ? (project?.projectTitle.length > 60 ? project?.projectTitle.slice(0, 60) + "..." : project?.projectTitle) : project?.projectTitle.length > 40 ? project?.projectTitle.slice(0, 40) + "..." : project?.projectTitle}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {/* custom paginATION */}

                                                        <div>
                                                            {totalPages > 0 && (
                                                                <Pagination
                                                                    totalPages={totalPages}
                                                                    currentPage={activePage}
                                                                    onPageChange={setActivePage}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>



                            {/* education wrapper  */}
                            <div className="layoutDesign" id="educationWrapper">
                                {resumeData !== null && (
                                    <>
                                        <div className="row d-flex justify-content-start align-items-center">
                                            <div className="col-12 col-md-12 d-flex justify-content-start align-items-center" style={{ minWidth: "150px" }}>
                                                <p style={{ color: "#333333", fontSize: "22px", letterSpacing: "0.5px", fontWeight: "700", marginTop: "15px" }}>Education</p>
                                            </div>


                                            {resumeData && resumeData?.resume.Education.length !== 0 && (
                                                <div className="col-12 col-md-12 ">
                                                    <>
                                                        {resumeData?.resume.Education.map((data) => {
                                                            return (
                                                                <>
                                                                    <div className="row" style={{ marginBottom: "10px" }}>
                                                                        <div className="col-md-12 col-12">
                                                                            <div style={{ color: "#4A4E50" }}>
                                                                                <p style={{ fontSize: "15px", color: "#404040" }}>
                                                                                    <b>{data.degree}</b>
                                                                                </p>
                                                                                <div style={{ color: "#6E6E6E", fontSize: "15px", marginTop: "-8px" }}>
                                                                                    <p>{data.school}</p>
                                                                                    {data.specialization ? <p style={{ marginTop: "-8px" }}>({data.specialization})</p> : ""}
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {data.startDate.slice(0, 4)} - {data.endDate.slice(0, 4)}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {data.performanceScale}: {data.performance}
                                                                                        {data.performanceScale === "Percentage" && "%"}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            {/* work experience wrapper */}
                            <div className="layoutDesign" id="workExperienceWrapper">
                                {resumeData !== null && (
                                    <>
                                        <div className="row d-flex justify-content-start align-items-center">
                                            <div className="col-10 col-md-11">
                                                <p style={{ color: "#333333", fontSize: "22px", letterSpacing: "0.5px", fontWeight: "700", margin: "8px 0px" }}>Work Experience {resumeData && resumeData?.resume?.Job && calculateTotalExperience(resumeData?.resume?.Job, resumeData?.resume?.Internship) && `${calculateTotalExperience(resumeData?.resume?.Job, resumeData?.resume?.Internship)}`}</p>
                                            </div>


                                            {resumeData && resumeData?.resume?.Job.length !== 0 && (
                                                <div className="col-md-12 col-12 mobile-column-to-increase-width">
                                                    <>
                                                        {resumeData?.resume?.Job.map((data) => {
                                                            return (
                                                                <>
                                                                    <div className="row" style={{ marginBottom: "10px" }}>
                                                                        <div className="col-md-12 col-12">
                                                                            <div>
                                                                                <p style={{ fontSize: "15px", color: "#404040" }}>
                                                                                    <b>{data.profile} </b>
                                                                                </p>
                                                                                <div style={{ color: "#6E6E6E", fontSize: "15px", marginTop: "-8px" }}>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {data.organization}, {data.location}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        Job <RxDotFilled style={{ color: "#464545" }} /> {MONTH_DETAILS[parseInt(data.startDate.slice(5, 7), 10)] + " " + data.startDate.slice(0, 4)} - {MONTH_DETAILS[parseInt(data.endDate.slice(5, 7), 10)] + " " + data.endDate.slice(0, 4)} {<MonthCalculator startDate={data.startDate} endDate={data.endDate} />}
                                                                                    </p>

                                                                                    <SeeMoreLess text={data?.description} maxLength={350} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>


                            {/* skills wrapper */}
                            <div className="layoutDesign" id="skillsWrapper" style={{ marginBottom: "20px" }}>
                                {resumeData !== null && (
                                    <>
                                        <div className="row d-flex justify-content-start align-items-center">
                                            <div className="col-10 col-md-11 d-flex justify-content-start align-items-center" style={{ minWidth: "150px" }}>
                                                <p style={{ color: "#333333", fontSize: "22px", letterSpacing: "0.5px", fontWeight: "700", marginTop: "15px" }}>Skills</p>
                                            </div>

                                            {resumeData && resumeData?.resume?.Skills.length !== 0 && (
                                                <div className="card skills-card">
                                                    <>
                                                        <div className="row g-4" style={{ pointerEvents: "none" }}>
                                                            {resumeData?.resume?.Skills.map((data) => (
                                                                <div key={data._id} className="col-12 col-md-6">
                                                                    <div className="skill-item">
                                                                        <div className="row align-items-start">
                                                                            <div className="col-9 col-sm-8">
                                                                                <h3 className="skill-name">{data.name}</h3>
                                                                                <p className="skill-level">{data.level}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                </div>
                                            )}

                                        </div>
                                    </>
                                )}
                            </div>

                            {user?.role === "Employer" && <div style={{ display: "flex", justifyContent: "end", maxWidth: "1200px", margin: "auto" }}>
                                <button className="shortcut-newappliedjobForPreview" style={{ border: "1px solid #1177d6" }} onClick={handleChatClick}> Message </button>
                            </div>}


                        </div>
                    </div>
                </div>
            ) : (
                <div className="" style={{ textAlign: "center", paddingBottom: "220px" }}>
                    <p
                        style={{
                            color: "#4B92C8",
                            fontWeight: "500",
                            fontSize: "20px",
                            marginLeft: "43px",
                            marginBottom: "5px",
                            marginTop: "220px",
                        }}
                    >
                        Loading..
                    </p>
                    <PropagateLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" style={{ marginLeft: "27px" }} />
                </div>
            )}
            <ChatDialogtalent
                candidates={resumeData && resumeData?._id} //userid
                appliedJobId={"6613d85f0ef12e507f453a46"} //jobid
                employerId={user && user._id}
                name={resumeData && resumeData?.name}
                open={openPopup}
                onClose={() => setOpenPopup(false)}
            />
        </>
    );
};

export default PreviewCandidate;
