import axios from "axios";
import React, { useEffect, useState } from "react";
import './Modal.scss'
const getYouTubeEmbedUrl = (url) => {
    try {
        const urlObj = new URL(url);
        let videoId;

        if (urlObj.hostname === "youtu.be") {
            videoId = urlObj.pathname.substring(1);
        } else if (urlObj.pathname.includes("/shorts/")) {
            videoId = urlObj.pathname.split("/shorts/")[1];
        } else {
            videoId = urlObj.searchParams.get("v").substring(0, 11);
        }

        return `https://www.youtube.com/embed/${videoId}`;

    } catch (e) {
        console.error("Invalid URL", e);
        return null;
    }
};

const PreviewModel = ({ portfolioDetails }) => {
    const [previewData1, setPreviewData1] = useState(null);
    const [error1, setError1] = useState(null);
    const [isMarkdown, setisMarkdown] = useState(false);

    useEffect(() => {
        const fetchPreviewData1 = async (urls) => {
            try {
                setError1(false);
                const response = await axios.post(
                    "/api/user/gettingwebsiteorarticledata2",
                    { urls }
                );
                return response.data;
            } catch (error) {
                console.error("Error fetching preview data:", error);
                setError1(true);
                return [];
            }
        };

        const fetchAllPreviewData1 = async () => {
            if (portfolioDetails && portfolioDetails.webLink.length > 0) {
                const urls = portfolioDetails.webLink.map((link) => link.url);
                const previews = await fetchPreviewData1(urls);
                const previewsWithTimestamps = previews.map((preview, index) => ({
                    ...preview,
                    timestamp:
                        portfolioDetails.webLink[index]?.timestamp ||
                        new Date().toISOString(),
                }));
                setPreviewData1(previewsWithTimestamps);
            }
        };

        fetchAllPreviewData1();
    }, [portfolioDetails]);

    const combinedData = [
        ...(portfolioDetails.photo || []).map((item) => ({
            ...item,
            type: "photo",
        })),
        ...(portfolioDetails.videoLink || []).map((item) => ({
            ...item,
            type: "videoLink",
        })),
        ...(portfolioDetails.webLink || []).map((item) => ({
            ...item,
            type: "webLink",
        })),
        ...(portfolioDetails.textContent || []).map((item) => ({
            ...item,
            type: "textContent",
        })),
    ];

    combinedData.sort((a, b) => {
        const dateA = new Date(a?.timestamp || "1970-01-01T00:00:00Z"); // Default to the epoch time if no timestamp
        const dateB = new Date(b?.timestamp || "1970-01-01T00:00:00Z");
        return dateA - dateB;
    });

    return (
        <div style={{ marginBottom: "25px" }}>
            {combinedData.length > 0 &&
                combinedData[0]?.timestamp &&
                combinedData.map((item, index) => {
                    if (item.type === "textContent") {
                        return (
                            <div
                                key={index}
                                style={{

                                    padding: "15px",
                                    flex: 1,
                                    border: "2px solid #2092c7",
                                    borderRadius: "8px",
                                    marginBottom: "20px",
                                    backgroundColor: "#f9f9f9",
                                    transition: "height 0.3s ease-in-out",
                                    minHeight: "180px",
                                }}
                            >
                                <h5
                                    style={{
                                        fontSize: "25px",
                                        fontWeight: "500",
                                        marginBottom: "15px",

                                    }}
                                >
                                    {item.heading}
                                </h5>
                                <p
                                    style={{
                                        fontSize: "18px",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: item.content.replace(/\n/g, "<br />"),
                                        }}
                                    />
                                </p>
                            </div>
                        );
                    } else if (item.type === "photo") {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginBottom: "25px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src={item.url}
                                    alt={`Project Photo ${index + 1}`}
                                    className="uploaded-image"
                                />
                            </div>
                        );
                    } else if (item.type === "videoLink") {
                        return (
                            <div key={index} style={{ marginBottom: "10px" }}>
                                <iframe
                                    width="100%"
                                    height="450"
                                    src={getYouTubeEmbedUrl(item.url)}
                                    title="Preview Introduction"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    style={{ borderRadius: "8px" }}
                                ></iframe>
                            </div>
                        );
                    } else if (item.type === "webLink") {
                        const matchingPreview = previewData1?.find(
                            (preview) => preview.timestamp === item.timestamp
                        );

                        return (
                            <div key={index} style={{ marginBottom: "25px" }}>
                                {matchingPreview && !error1 ? (
                                    <a
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: "none" }}
                                    >
                                        <div
                                            className="preview-container form-group mt-4 mb-2"
                                            style={{
                                                backgroundColor: "#f9f9f9",
                                                paddingTop: "10px",
                                                paddingBottom: "10px",
                                                paddingLeft: "5px",
                                                paddingRight: "10px",
                                                borderRadius: "8px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                {matchingPreview.image && (
                                                    <img
                                                        src={matchingPreview.image}
                                                        alt="Link preview"
                                                        style={{
                                                            width: "100px",
                                                            height: "100px",
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                )}
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <h3
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#2092c7",
                                                            fontWeight: "500",
                                                            textDecoration: "underline",
                                                            marginBottom: "8px",
                                                        }}
                                                    >
                                                        {matchingPreview.title}
                                                    </h3>
                                                    <p
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "#555",
                                                        }}
                                                    >
                                                        {matchingPreview.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ) : (
                                    <div className="loading-container">
                                        <p>
                                            Loading<span className="dot-animation"></span>
                                        </p>
                                    </div>
                                )}
                            </div>
                        );
                    }
                    return null;
                })}
            {!combinedData[0]?.timestamp && (
                <>
                    {portfolioDetails.textContent &&
                        portfolioDetails.textContent.length > 0 && (
                            <div style={{ marginBottom: "25px" }}>
                                {portfolioDetails.textContent.map((text, index) => (
                                    <div key={index}>
                                        <h5
                                            style={{
                                                fontSize: "25px",
                                                fontWeight: "500",
                                                marginBottom: "15px",
                                                textAlign: "justify",
                                            }}
                                        >
                                            {text.heading}
                                        </h5>
                                        <p
                                            style={{
                                                fontSize: "18px",
                                                marginBottom: "15px",
                                            }}
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: text.content.replace(/\n/g, "<br />"),
                                                }}
                                            />
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                    {portfolioDetails.photo && portfolioDetails.photo.length > 0 && (
                        <div style={{ marginBottom: "25px" }}>
                            {portfolioDetails.photo.map((imgSrc, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginBottom: "25px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={imgSrc.url}
                                        alt={`Project Photo ${index + 1}`}
                                        style={{
                                            marginBottom: "10px",
                                            height: "400px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {portfolioDetails.videoLink &&
                        portfolioDetails.videoLink.length > 0 && (
                            <div style={{ marginBottom: "25px" }}>
                                {portfolioDetails.videoLink.map((link, index) => (
                                    <div key={index} style={{ marginBottom: "10px" }}>
                                        <iframe
                                            width="100%"
                                            height="400"
                                            src={getYouTubeEmbedUrl(Object.values(link).join(''))}
                                            title="Preview Introduction"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                ))}
                            </div>
                        )}

                    {portfolioDetails?.webLink &&
                        portfolioDetails?.webLink.length > 0 && (
                            <div style={{ marginBottom: "25px" }}>
                                {portfolioDetails?.webLink.map((link, index) => (
                                    <div key={index} style={{ marginBottom: "25px" }}>
                                        {previewData1 && previewData1[index] && !error1 && (// Add check for previewData1
                                            <a
                                                href={link?.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: "none" }}
                                            >
                                                <div
                                                    className="preview-container form-group mt-4 mb-2"
                                                    style={{
                                                        backgroundColor: "#f9f9f9",
                                                        paddingTop: "10px",
                                                        paddingBottom: "10px",
                                                        paddingLeft: "5px",
                                                        paddingRight: "10px",
                                                        borderRadius: "8px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: "10px",
                                                        }}
                                                    >
                                                        {previewData1[index].image && (
                                                            <img
                                                                src={previewData1[index].image}
                                                                alt="Link preview"
                                                                style={{
                                                                    width: "100px",
                                                                    height: "100px",
                                                                    borderRadius: "5px",
                                                                }}
                                                            />
                                                        )}
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }}
                                                        >
                                                            <h3
                                                                style={{
                                                                    fontSize: "16px",
                                                                    color: "#2092c7",
                                                                    fontWeight: "500",
                                                                    textDecoration: "underline",
                                                                    marginBottom: "8px",
                                                                }}
                                                            >
                                                                {previewData1[index].title}
                                                            </h3>
                                                            <p
                                                                style={{
                                                                    fontSize: "14px",
                                                                    color: "#555",
                                                                }}
                                                            >
                                                                {previewData1[index].description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                </>
            )}
        </div>
    );
};

export default PreviewModel;
