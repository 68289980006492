import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logEvent } from '../../utils/Analytics';
const EmployerConnectInfo = () => {
    const { user } = useSelector((state) => state.user);
    const [connectCredit, setConnectCredit] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        const getConnectCreditInfo = async () => {
            try {
                const response = await axios.get(`/api/employer/getConnectCreditInfomrationofEmployer/${user?._id}`);
                setConnectCredit(response.data.connectCredit);
            } catch (error) {
                console.error('Error fetching connectCredit:', error);
            }
        };

        if (user && user.role === 'User') {
            getConnectCreditInfo();
        }
    }, [user]);
    const handleBuyConnects = () => {
        logEvent("Button", `Clicked buy connect button`, user ? user.role : "Guest");

        navigate('/freelancerbuyconnects');
    };
    const handleBack = () => {
        navigate('/')
    }
    return (
        <>
            <div className='myapplicationsbuymain'>
                <div className='my-applicationsbuyconnects2'>
                    <div className='my-applicationsbuyconnectsheader' style={{ height: '50px', fontWeight: '700', padding: '17px', fontSize: "18px" }}>Connects Summary</div>
                    <div className='my-applicationsbuyconnectsbodyinformation' >
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ fontSize: '16px', fontWeight: '500', marginBottom: '11px' }}>Your Available Connects :</div>
                            <div><b>{connectCredit}</b></div>
                        </div>
                        <div>Applying for an hourly project requires <b>8</b> connects.</div>
                        <div>Applying for a fixed cost project requires <b>4</b> connects.</div>
                        <div>To view a freelancer's contact information (email, phone, direct chat) on the "Find Talent" page, you'll need to use <b>1</b> Connect.</div>
                        {/* <div><b>1</b> contact view requires <b>1</b> connect</div> */}
                        {/* <div>Viewing job seekers' contacts (email, phone, direct chat) on the "Job Seekers" page, requires <b>1</b> connect</div> */}

                        <div style={{ display: 'flex', alignItems: 'flex-end', alignSelf: 'end', gap: '10px' }}>
                            <button className='employerbuttonbacktodash' onClick={handleBack}>Back</button>
                            <button className="employerbuttontobuyinformation" onClick={handleBuyConnects}>Buy Connects</button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployerConnectInfo